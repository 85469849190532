import CommonRoomDetail from "../Component/CommonRoomDetail";
import ChangePassword from "../Screens/Main/ChandePassword/ChangePassword";
import ColorCurrentRoomStat from "../Screens/Main/ColorgameSetting/CurrentRoomStat";
import ColorGamesetting from "../Screens/Main/ColorgameSetting/Gamesetting";
import ColorRooms from "../Screens/Main/ColorgameSetting/Rooms";
import Dashboard from "../Screens/Main/Dashboard/Dashboard";
import FourtuneCurrentRoomStat from "../Screens/Main/FortuneGame/CurrentRoomStat";
import FourtuneGamesetting from "../Screens/Main/FortuneGame/Gamesetting";
import FourtuneRooms from "../Screens/Main/FortuneGame/Rooms";
import FundReqReport from "../Screens/Main/FundRequest/FundReqReport";
import FundRequest from "../Screens/Main/FundRequest/FundRequest";
import CurrentRoomStat from "../Screens/Main/GameSetting/CurrentRoomStat";
import Gamesetting from "../Screens/Main/GameSetting/Gamesetting";
import Rooms from "../Screens/Main/GameSetting/Rooms";
import News from "../Screens/Main/News/NewsList";
import PaymentSetting from "../Screens/Main/PaymentSetting/PaymentSetting";
import ShowUnderPeople from "../Screens/Main/Settlement/ShowUnderPeople";
import FetchSettlementHistory from "../Screens/Main/Settlement/FetchsettleHistory";
// import Promocode from "../Screens/Main/PromoCode/Promocode";
import SubAdmin from "../Screens/Main/SubAdmin/SubAdmin";

import UnderPeople from "../Screens/Main/UnderPeople/Index";
import UnderPeopleTable from "../Screens/Main/UnderPeople/UnderPeopleTable";
import WalletTxn from "../Screens/Main/UnderPeople/WalletTxn";
import WalletReport from "../Screens/Main/Wallet/WalletReport";
import WithdrawalRequest from "../Screens/Main/WithdrawalRequest/WithdrawalRequest";
import NotAvailable from "../Screens/NotAvailable";
import ProtectedAuth from "./PrivateAuth";
import SettlementFirstPage from "../Screens/Main/Settlement/SettlementFirstPage";


export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/Dashboard",
          element: <Dashboard />,
        },
        {
          path: "/subAdmin",
          element: <SubAdmin />,
        },
        {
          path: "/users",
          element: <UnderPeople />,
        },
        {
          path: "/under-admin",
          element: <UnderPeopleTable />,
        },
        {
          path: "/roullete-setting",
          element: <Gamesetting />,
        },
        {
          path: "/color-setting",
          element: <ColorGamesetting />,
        },
        {
          path: "/wallet-txn",
          element: <WalletTxn />,
        },
        {
          path: "/fund-req",
          element: <FundRequest />,
        },
        {
          path: "/withdrawal-req",
          element: <WithdrawalRequest />,
        },
        {
          path: "/payment-setting",
          element: <PaymentSetting />,
        },
        {
          path: "/req-report",
          element: <FundReqReport />,
        },
        {
          path: "/current-room-stat",
          element: <CurrentRoomStat />,
        },
        {
          path: "/color-current-room-stat",
          element: <ColorCurrentRoomStat />,
        },
        {
          path: "/rooms",
          element: <Rooms />,
        },
        {
          path: "/color-rooms",
          element: <ColorRooms />,
        },
        {
          path: "/change-password",
          element: <ChangePassword />,
        },
        {
          path: "/change-password",
          element: <ChangePassword />,
        },
        {
          path: "/wallet",
          element: <WalletReport />,
        },
        {
          path: "/settlement",
          element: <SettlementFirstPage />,
        },
        {
          path: "/settlement/:type/:id?",
          element: <ShowUnderPeople />,
        },
        {
          path: "/settlement-history/:type/:id?",
          element: <FetchSettlementHistory />,
        },
        // {
        //   path: "/promo-code",
        //   element: <Promocode />,
        // },
        {
          path: "/news",
          element: <News />,
        },
        {
          path: "/not-available",
          element: <NotAvailable />,
        },

        {
          path: "/room-detail",
          element: <CommonRoomDetail />,
        },
        // ERROR ROUTES ENDED


        //FOURTUNE ROUTES
        {
          path: "/fourtune-setting",
          element: <FourtuneGamesetting />,
        },
        {
          path: "/fourtune-rooms",
          element: <FourtuneRooms />,
        },
        {
          path: "/fourtune-current-room-stat",
          element: <FourtuneCurrentRoomStat />,
        },
      ],
    },
  ];
};

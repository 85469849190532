import React, { useEffect, useState } from "react";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { useDispatch } from "react-redux";
import {  forTuneChooseWinner, fourTuneOptions, fourTuneRoomStats } from "../../../ApiServices/Apifun";
import { FaUserAlt } from "react-icons/fa";
import { Pagination, Popconfirm, Skeleton, Table } from "antd";
import { AiFillEye } from "react-icons/ai";
import ProfileModal from "./ProfileModal";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";
import { useNavigate } from "react-router-dom";
import { GiPodiumWinner } from "react-icons/gi";

const FourtuneCurrentRoomStat = () => {
  const profileDetails = getLocalStorageItem("userDetails");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [RoomDetail, setRoomDetail] = useState({});
  const [bettingDetail, setBettingDetail] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [current, setCurrent] = useState(1);
  const [openProfileModal, setOpenProfileModal] = useState(false);
const [profileDetail,setProfileDetail]=useState(null)
const [selectedOption, setSelectedOption] = useState(null);
const[fourtuneOptionList,setFourtuneOptionList]=useState([])

  const RoomStatistics = () => {
    setShowSkeleton(true);
    setLoading(true)
    fourTuneRoomStats(`?page=${current}&limit=10`)
      .then((res) => {
        if (res.status) {
          const {
            room_bet_close_time,
            room_close_time,
            room_game_slug,
            room_id,
            room_start_time,
            room_status,
            winner_option,
          } = res.roomDetail;
          const data = {
            "Room Id": room_id,
            "Game Name": room_game_slug,
            "Bet Close Time": new Date(room_bet_close_time).toLocaleString().split(",").join(" "),
            "Room Close Time": new Date(room_close_time).toLocaleString().split(",").join(" "),
            "room Start Time": new Date(room_start_time).toLocaleString().split(",").join(" "),
            "Room Status": room_status ? "Active" : "In Active",
            "Winner Option": winner_option,
          };
          setRoomDetail(data);
          setTotalPages(res.pagination.totalBettingDocuments)
          setBettingDetail(
            res.bettingDetails.map((i, index) => {
              return {
                ...i,
                sno: index + 1,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setShowSkeleton(false)
        setLoading(false)
      });
  };
  const getFourtuneOption=()=>{
    fourTuneOptions().then(res=>{
      if(res.status){
        setFourtuneOptionList(res.data)
      }
    }).catch(err=>console.log(err))
  }
  useEffect(() => {
    if (profileDetails.role === "sub_admin") {
      if (!getLocalStorageItem("services").includes(7)) {
        navigate("/not-available");
        dispatch(setselectedTab("12.2"));
      }
    }
    if(profileDetails.role==="admin"){
      dispatch(setselectedTab("12.2"));
      dispatch(setHeading("Fourtune Game Current Room Stats"));
      RoomStatistics();
      getFourtuneOption()
    }else{
      navigate(-1)
    }
   
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Choosen Option",
      dataIndex: "choose_option_id",
      key: "choose_option_id",
      render: (text,record) => (
        <div className="flex justify-center items-center gap-2">
          {/* {text.map((i) => ( */}
          <p
            className={`text-center ${
              record?.result?.option_name === "Yellow"
                ? "bg-yellow-600 text-white"
                : record?.result?.option_name === "Red"
                ? "bg-red-700 text-white"
                : record?.result?.option_name === "Blue"
                ? "bg-blue-600 text-white"
                : "text-black"
            } rounded-md p-1`}
          >
            {record?.result?.option_name}
          </p>
          {/* ))} */}
        </div>
      ),
    },
    {
      title: "Game Name",
      dataIndex: "game_slug",
      key: "game_slug",
      render: (text) => <p>color_game</p>,
    },
    {
      title: "Bet Amount",
      dataIndex: "bet_amount",
      key: "bet_amount",
      render: (text) => <p>{text ?? "N/A"} Rs.</p>,
    },
    {
      title: "Bet Type",
      dataIndex: "bettype",
      key: "bettype",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Plateform service amount",
      dataIndex: "platform_service_amt",
      key: "platform_service_amt",
      render: (text) => <p>{text ?? "N/A"} Rs.</p>,
    },
    {
      title: "Winning Amount",
      dataIndex: "winning_amt",
      key: "winning_amt",
      render: (text) => <p>{text} Rs.</p>,
    },
    {
      title: "View Profile",
      dataIndex: "txn.",
      key: "txn.",
      render: (text, record) => (
        <p
        onClick={()=>{
            setProfileDetail(record.user_id)
          setOpenProfileModal(true)
        }}
        >
          <AiFillEye
            className="bg-primary text-white flex justify-center items-center p-2"
            size={25}
          />
        </p>
      ),
    },
    {
      title: "Choose Winner",
      dataIndex: "txn.",
      key: "txn.",
      render: (text, record) => (
        <Popconfirm
          description={
            <div className="grid grid-cols-5  place-items-center">
              {fourtuneOptionList.map((i, index) => (
                <p key={index}
                  onClick={() => setSelectedOption(i.id)}
                  className={`${
                    selectedOption === i.id ? "bg-green-800 px-2 text-white" : ""
                  } cursor-pointer`}
                >
                  {i.option_name}
                </p>
              ))}
            </div>
          }
          onConfirm={() =>
            forTuneChooseWinner({
              optionId: selectedOption,
              roomId: record.room_id,
            })
              .then((res) => {
                if (res.status) {
                  dispatchtoast(res.message);
                }
              })
              .catch((err) => console.log(err))
          }
          icon={null}
          okText="Set"
          okButtonProps={{ className: "bg-primary" }}
          placement="leftBottom"
          title={"Set Winner"}
        >
          <p>
            <GiPodiumWinner
              className="bg-primary text-white flex justify-center items-center p-2"
              size={25}
            />
          </p>
        </Popconfirm>
      ),
    },
  ];
  return (
    <>
      {showSkeleton ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 place-items-center">
          {[1, 2, 3, 4, 5, 6].map((i,index) => (
            <div key={index} className="  h-[100px]   w-full">
              <Skeleton.Button
                active={true}
                size={200}
                shape={"default"}
                block={false}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 place-items-center">
          {Object.keys(RoomDetail).map((key, index) => {
            return (
              <div
                key={index}
                className=" shadow-lg hover:bg-gray-400 transition-all cursor-pointer shadow-gray-300 bg-white p-4 w-full"
              >
                <p className="flex justify-center items-center font-bold text-md text-primary">
                  {RoomDetail[key]}
                </p>
                <div className="flex text-lg mt-4 justify-between items-center">
                  <p className="font-semibold text-sm capitalize">
                    {key.split(/(?=[A-Z])/).join(" ")}
                  </p>
                  <FaUserAlt />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="bg-primary mt-4 p-4 text-white font-bold rounded-sm">
        <p>Option & it's stats</p>
      </div>
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={columns}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={bettingDetail}
        />
        <div className="mt-2 mb-8 flex justify-end items-center">
          <Pagination
            showQuickJumper={false}
            defaultCurrent={current}
            pageSize={10}
            current={current}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
      </div>
      {openProfileModal && (
        <ProfileModal open={openProfileModal} setOpen={setOpenProfileModal} object={profileDetail}/>
      )}
    </>
  );
};

export default FourtuneCurrentRoomStat;

import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AddNews, GetnewsUserType } from "../../../ApiServices/Apifun";
import { dispatchtoast } from "../../../Utils";

const AddNewsModal = ({ open, setOpen, apiCall = () => {} }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const [type,setType]=useState([])
  const formref = useRef();
  const [isActive, setisActive] = useState(0);

  const getType=()=>{
GetnewsUserType().then(res=>{
  if(res.status){
   setType(res.data.map((i)=>{
    return {
      label:i.user_type,
      value:i.user_type
    }
   }))
  }
}).catch(err=>console.log(err))
  }
  const handleCreate = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("description", val.description);
    formdata.append("heading", val.heading);
    formdata.append("type", val.type);
    formdata.append("is_active", isActive ? 1 : 0);

    AddNews(formdata)
      .then((res) => {
        if (res.status) {
          apiCall();
          setOpen(false);
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  useEffect(()=>{
getType()
  },[])
  return (
    <>
      <Modal footer={null} open={open} onCancel={() => setOpen(false)}>
        <Form
          form={form}
          ref={formref}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinishFailed={(err) => console.log(err)}
          onFinish={(val) => handleCreate(val)}
        >
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-8 place-items-start">
            <Form.Item
              name={`heading`}
              label={"Heading"}
              className="w-full"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter heading!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <Input placeholder="Enter Heading" name="detail" />
            </Form.Item>{" "}
            <Form.Item
              name={`description`}
              label={"Description"}
              className="w-full"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter description!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <Input placeholder="Enter Description" name="detail" />
            </Form.Item>{" "}
            <Form.Item
              name={`type`}
              label={"Type"}
              className="w-full"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter type!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <Select placeholder="Select type" options={
                type
              }/>
            </Form.Item>{" "}
            <Form.Item
              name={`is_active`}
              label={"Is Active"}
              className="w-full"
              labelCol={{ span: 24 }}
            >
              <Checkbox
                onChange={(e) => setisActive(e.target.checked)}
                checked={isActive}
              >
                Is Active
              </Checkbox>
            </Form.Item>{" "}
          </div>
          <div className="flex justify-end items-center ">
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Send
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddNewsModal;

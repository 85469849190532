import { NavLink } from "react-router-dom";

import {
  AiFillSetting,
  AiOutlineHistory,
  AiOutlineHome,
  AiOutlineWallet,
} from "react-icons/ai";
import { BiSolidPieChartAlt2 } from "react-icons/bi";
import { PiMoneyBold, PiUsersThreeLight } from "react-icons/pi";
import { LiaGamepadSolid } from "react-icons/lia";
import { IoIosColorPalette } from "react-icons/io";

import { VscGraphLine } from "react-icons/vsc";

import { MdPayment } from "react-icons/md";
import { BsCash } from "react-icons/bs";
import { RiAdminFill } from "react-icons/ri";
import { getLocalStorageItem } from "../Utils";
import { GiCartwheel } from "react-icons/gi";
import { FaMoneyBill, FaNewspaper } from "react-icons/fa";

export const Menuitems = () => {
  const profileDetail = getLocalStorageItem("userDetails");
  const checkpermission=(id,to)=>{
    if(profileDetail.role==="sub_admin"){
      if(getLocalStorageItem("services").includes(id)){
        return to
      }else {
        return "not-available"
      }
    }else {
      return to
    }
  }
  const list = [
    {
      key: "1",
      icon: <AiOutlineHome />,
      label: (
        <NavLink to={"/dashboard"}>
          <p>Dashboard</p>
        </NavLink>
      ),
    },

    {
      key: "2",
      icon: <PiUsersThreeLight />,
      label: (
        <NavLink to={"/users"}>
          <p>Users</p>
        </NavLink>
      ),
    },
    {
      key: "10",
      icon: <RiAdminFill />,
      label: (
        <NavLink to={"/subAdmin"}>
          <p>SubAdmin</p>
        </NavLink>
      ),
    },
    {
      key: "3",
      icon: <MdPayment />,
      label: (
        <NavLink to={checkpermission(5,"/payment-setting")}>
          <p>Payment Settings</p>
        </NavLink>
      ),
    },
    {
      key: "4",
      icon: <BsCash />,
      label: (
        <NavLink to={checkpermission(1,"/fund-req") }>
          <p>Fund Requests</p>
        </NavLink>
      ),
    },
    {
      key: "5",
      icon: <PiMoneyBold />,
      label: (
        <NavLink to={checkpermission(2,"/withdrawal-req")}>
          <p>withdrawl requests</p>
        </NavLink>
      ),
    },
    {
      key: "6",
      icon: <BiSolidPieChartAlt2 />,
      label: (
        <NavLink>
          <p>Roullete</p>
        </NavLink>
      ),
      children: [
        {
          key: "6.1",
          icon: <AiFillSetting />,
          label: (
            <NavLink to={checkpermission(3,"/roullete-setting")} state={{ from: "roulette_game" }}>
              <p>Setting</p>
            </NavLink>
          ),
        },
        {
          key: "6.2",
          icon: <VscGraphLine />,
          label: (
            <NavLink to={checkpermission(3,"/current-room-stat")}>
              <p>Current Room Statistics</p>
            </NavLink>
          ),
        },
        {
          key: "6.3",
          icon: <LiaGamepadSolid />,
          label: (
            <NavLink to={checkpermission(3,"/rooms")}>
              <p>Rooms</p>
            </NavLink>
          ),
        },
      ],
    },
    {
      key: "7",
      icon: <IoIosColorPalette  />,
      label: (
        <NavLink>
          <p>Color Game</p>
        </NavLink>
      ),
      children: [
        {
          key: "7.1",
          icon: <AiFillSetting />,
          label: (
            <NavLink to={checkpermission(7,"/color-setting")} state={{ from: "color_game" }}>
              <p>Setting</p>
            </NavLink>
          ),
        },
        {
          key: "7.2",
          icon: <VscGraphLine />,
          label: (
            <NavLink to={checkpermission(7,"/color-current-room-stat")}>
              <p>Current Room Statistics</p>
            </NavLink>
          ),
        },
        {
          key: "7.3",
          icon: <LiaGamepadSolid />,
          label: (
            <NavLink to={checkpermission(7,"/color-rooms")}>
              <p>Rooms</p>
            </NavLink>
          ),
        },
      ],
    },
    {
      key: "12",
      icon: <GiCartwheel  />,
      label: (
        <NavLink>
          <p>Fortune Game</p>
        </NavLink>
      ),
      children: [
        {
          key: "12.1",
          icon: <AiFillSetting />,
          label: (
            <NavLink to={checkpermission(4,"/fourtune-setting")} state={{ from: "fourtune_wheel" }}>
              <p>Setting</p>
            </NavLink>
          ),
        },
        {
          key: "12.2",
          icon: <VscGraphLine />,
          label: (
            <NavLink to={checkpermission(4,"/fourtune-current-room-stat")}>
              <p>Current Room Statistics</p>
            </NavLink>
          ),
        },
        {
          key: "12.3",
          icon: <LiaGamepadSolid />,
          label: (
            <NavLink to={checkpermission(4,"/fourtune-rooms")}>
              <p>Rooms</p>
            </NavLink>
          ),
        },
      ],
    },
    {
      key: "8",
      icon: <AiOutlineHistory />,
      label: (
        <NavLink to={"/req-report"}>
          <p>Fund Request Report</p>
        </NavLink>
      ),
    },
    {
      key: "9",
      icon: <AiOutlineWallet />,
      label: (
        <NavLink to={checkpermission(6,"/wallet")}>
          <p>Wallet</p>
        </NavLink>
      ),
    },
    {
      key: "1001",
      icon: <FaMoneyBill />,
      label: (
        <NavLink to={"/settlement"}>
          <p>Settlement</p>
        </NavLink>
      ),
    },
    // {
    //   key: "11",
    //   icon: <RiCouponLine />,
    //   label: (
    //     <NavLink to={"/promo-code"}>
    //       <p>PromoCodes</p>
    //     </NavLink>
    //   ),
    // },
    {
      key: "13",
      icon: <FaNewspaper />,
      label: (
        <NavLink to={"/news"}>
          <p>News</p>
        </NavLink>
      ),
    },
  ];

  if (profileDetail.role?.toLowerCase() === "admin".toLowerCase()) {
    let temp = [...list];
    temp[1].label = (
      <NavLink to={"/users"}>
        <p>Distributer</p>
      </NavLink>
    );
    const filter = temp.filter((i) => i.key !== "4");
    return filter;
  } else if (
    profileDetail.role?.toLowerCase() === "distributor".toLowerCase()
  ) {
    const filter =list.filter((i) => i.key !== "10"  && i.key !== "6"&& i.key !== "7"&& i.key !== "12" && i.key !== "13")
    filter[1].label = (
      <NavLink to={"/users"}>
        <p>Dealer</p>
      </NavLink>
    );
    return filter;
  } else if (profileDetail.role?.toLowerCase() === "dealer".toLowerCase()) {
    const filter =list.filter((i) => i.key !== "10" && i.key !== "6"&& i.key !== "7"&& i.key !== "12" && i.key !== "13")

    filter[1].label = (
      <NavLink to={"/users"}>
        <p>User</p>
      </NavLink>
    );
    return filter;
  }else if(profileDetail.role?.toLowerCase() === "sub_admin".toLowerCase()){
    const filter = list.filter((i) => i.key !== "10");
    filter[1].label = (
      <NavLink to={"/users"}>
        <p>Distributer</p>
      </NavLink>
    );
    return filter;
  }
  if (profileDetail.role?.toLowerCase() !== "admin".toLowerCase()) {
    const filter = list.filter((i) => i.key !== "10");
    return filter;
  }
};

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { Button, Table } from "antd";
import {
} from "../../../ApiServices/Apifun";
import { useNavigate } from "react-router-dom";
import { FaHistory } from "react-icons/fa";
import { getLocalStorageItem } from "../../../Utils";

const SettlementFirstPage = () => {
  const profileDetail = getLocalStorageItem("userDetails");
  const dispatch = useDispatch();
  const navigate = useNavigate();
//   const { type, id } = useParams();
//   const [dataSource, setDataSource] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [currentPage, setCurrent] = useState(1);
//   const [totalPages, setTotalPages] = useState(null);
//   const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    dispatch(setselectedTab("1001"));
    dispatch(setHeading("Settlemet"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const AdminColumns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Type",
      dataIndex: "name",
      key: "name",
      render: () => <p className="capitalize">{profileDetail.role}</p>,
    },
    {
      title: "History",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <Button
          className={`bg-primary px-4  text-white text-center  `}
          onClick={() => {
            const type=getLocalStorageItem("userDetails")?.role
            const temp =
            type === "distributor"
              ? "dealer"
              : type === "dealer"
              ? "user"
              : "distributor";
            navigate(`/settlement-history/${temp}/${getLocalStorageItem("userDetails")?.userId}`);
          }}
        >
          <FaHistory />
        </Button>
      ),
    },
    {
      title: "Show",
      dataIndex: "show",
      key: "show",
      render: (text, record) => (
        <button
          className="bg-primary px-4 capitalize text-white text-center cursor-pointer"
          onClick={() => {
            const type=getLocalStorageItem("userDetails")?.role
            const temp =
            type === "distributor"
              ? "dealer"
              : type === "dealer"
              ? "user"
              : "distributor";
            navigate(`/settlement/${temp}/${getLocalStorageItem("userDetails")?.userId}`);
          }}
        >
          show  {getLocalStorageItem("userDetails")?.role === "distributor" ? "dealer" : getLocalStorageItem("userDetails")?.role === "dealer" ? "user" : "distributor"}
        </button>
      ),
    },
  ];

  return (
    <>
        <div className="mt-4">
      
            <Table
              className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
              columns={AdminColumns}
            //   loading={loading}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  className: "cursor-pointer text-[12px]",
                };
              }}
              dataSource={[{ sno: 1 }]}
            />         
        </div>
      {/* ) : (
        <></>
      )} */}
    </>
  );
};

export default SettlementFirstPage;

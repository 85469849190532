export const Authendpoint = {
  Login: "admin/login",
  logout: "admin/log-out",
  changePassword: "admin/change-password",
};
export const Globalendpoints = {
  fetchWallet:"admin/fetch-wallet",
  gameDashboard: "admin/dashboard-count",
  getWalletTxn: "admin/get-wallet-transaction",
  distributorList: "admin/fetch-distributor-list",
  dealerList: "admin/fetch-dealer-list",
  userList: "admin/fetch-user-list",
  addDistributor: "admin/add-distributor",
  addDealer: "admin/add-dealer",
  addUser: "admin/add-user",
  updateDistributor: "admin/update-distributor",
  updateDealer: "admin/update-dealer",
  updateUser:"admin/update-user-status/",
  updatedealertoUserWallet:"admin/update-user-amount/",
  updateadmindisdealwallet:"admin/update-wallet-amount/",
  fetchgameSeeting: "admin/fetch-games",
  updateGameSetting: "admin/update-games",
  roulletecurrentRoomStat: "admin/current-room-stats",
  roulleteRoomDetail:"admin/bat-details-byRoom?",
  currentRoomStat: "admin/color/current-room-stats",
  colorRoomDetail:"admin/color/bat-details-byRoom?",
  allRooms: "admin/room-details",
  colorAllRooms:"admin/color/room-details",
  colorchooseWinner:"admin/color/choose-winner",
  colorOption:"admin/color/options",

  chooseWinner:"admin/choose-winner",

  currentRoomStatFortune: "admin/fourTune/current-room-stats",
  fortuneRoomDetail:"admin/fourTune/bat-details-byRoom?",
  fortuneAllRooms:"admin/fourTune/room-details",
  fortunechooseWinner:"admin/fourTune/choose-winner",
  fortuneOption:"admin/fourTune/options",


  fetchBankList:"admin/fetch-bank",
  addBank:"admin/add-bank-details",
  fetchUserBanks:"admin/fetch-bank-details",
  updateBankDetail:"admin/update-bank-details",


  DofundReq:"admin/fund-request",
  fetchFundReq:"admin/fetch-fund-request",
  fundReqAction:"admin/take-fund-action",

  DoWithdrawalReq:"admin/do-withdraw-req",
  fetchwithdrwalReq:"admin/fetch-withdraw-request",
  withdrawalReqAction:"admin/take-withdraw-action",

  getWalletReport:"admin/fetch-wallet-history",


  createSubAdmin:"sub_admin/create",
  getSubAdmin:"sub_admin/fetch-list",
  updateSubAdmin:'sub_admin/update',
  givePermission:"admin/give-permission",
  removePermission:"admin/remove-permission",

  createOffer:"admin/Create-offer",
  fetchOffers:"admin/fetch-all-offers",
  removeOffer:"/admin/remove-offer?",

updateSettlement:"settlement/updateSettlement",
fetchSettleHistory:'settlement/fetchSettlementHistory?userId=',
  addnews:"admin/set-news",
  newsUserType:"admin/news/user-type",
  getNews:"admin/get-news",
  editNews:"admin/toggle-status-news"
};

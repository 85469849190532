import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { Button, Popconfirm, Table } from "antd";
import {
  fetchSettleHistory,
  updateSettlement,
} from "../../../ApiServices/Apifun";
import { useNavigate, useParams } from "react-router-dom";
import CommonPagination from "../../../Utils/Pagination";
import { getLocalStorageItem } from "../../../Utils";

const FetchSettlementHistory = () => {
  const dispatch = useDispatch();
  const profileDetail = getLocalStorageItem("userDetails");
  const navigate = useNavigate();
  const { type, id } = useParams();
  const [totalAmt, setTotalAmt] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    dispatch(setselectedTab("1001"));
    dispatch(setHeading("Settlemet History"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getListData = async () => {
    setLoading(true);
    try {
      let res = await fetchSettleHistory(
        `${type === "admin" ? "" : id}&page=${currentPage}&limit=${pageSize}`
      );
      if (res.status) {
        setCurrent(res.page);
        setTotalPages(res.total);
        setTotalAmt(res.data.total_amount);
        setDataSource(
          res.data.historyData.map((i, index) => {
            return {
              id: i["_id"],
              sno: index + 1,
              GameName: i.game_name,
              RoomId: i.room_id,
              total_amount: i.total_amount,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // if (profileDetail.role !== "admin") {
      // navigate(-1);
    // } else {
      getListData();
    // }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, navigate]);
  const handleUpdateSettle = (id) => {
    setLoading(true);
    const payload = { recordIds: id };
    updateSettlement(payload)
      .then((res) => {
        if (res.success) {
          getListData();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Game Name",
      dataIndex: "GameName",
      key: "GameName",
    },
    {
      title: "Room Id",
      dataIndex: "RoomId",
      key: "RoomId",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },

    {
      title: "Total amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text) => <p>{text} Rs.</p>,
    },

    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <Popconfirm
          placement="leftTop"
          onConfirm={() => {
            handleUpdateSettle([record.id]);
          }}
          okButtonProps={{ className: "bg-primary hover:bg-primary" }}
          title={<p>Are you sure you want to settle?</p>}
        >
          <Button
            disabled={(record.total_amount = 0|| profileDetail?.role!=="admin")}
            className={`bg-primary px-4 text-white text-center ${(record.total_amount =
              0 ? "cursor-not-allowed" : "cursor-pointer")} `}
          >
            Settle
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      {/* {profileDetail.role === "admin" ? (
        <> */}
          {dataSource.length > 0 && (
            <div className="flex justify-between items-center">
              <p className="text-primary font-bold text-lg">
                Total Amount: {totalAmt} Rs.
              </p>
          {profileDetail?.role==="admin" &&     (type === "distributor" ||
                type === "dealer" ||
                type === "admin") && (
                <Popconfirm
                  placement="leftTop"
                  onConfirm={() => {
                    const Allids = dataSource.map((i) => i.id);
                    handleUpdateSettle(Allids);
                  }}
                  okButtonProps={{ className: "bg-primary hover:bg-primary" }}
                  title={<p>Are you sure you want to settle all?</p>}
                >
                  <button className="bg-primary px-4 text-white rounded-sm py-2">
                    Settle All
                  </button>
                </Popconfirm>
              )}
            </div>
          )}
          <div className="mt-4">
            <Table
              className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
              columns={columns}
              loading={loading}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  className: "cursor-pointer text-[12px]",
                };
              }}
              dataSource={dataSource}
            />
            <div className="mt-2 mb-8 flex justify-end items-center">
              <CommonPagination
                totalPages={totalPages}
                current={currentPage}
                pageSize={pageSize}
                sizeChanger={false}
                setCurrent={setCurrent}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </>
      // ) : (
      //   <></>
      // )}
    // </>
  );
};

export default FetchSettlementHistory;

import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown } from "antd";

import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { FaUserLarge } from "react-icons/fa6";
import { Menuitems } from "./Menuitems";
import { useDispatch, useSelector } from "react-redux";
import { clearAllRedux, setWalletBal } from "../Store/B2bslice";
import { FetchWallet, Getnews } from "../ApiServices/Apifun";
import { AiOutlineWallet } from "react-icons/ai";
import { FaBell } from "react-icons/fa";
import NotificationDrawer from "./NotificationDrawer";
import { getLocalStorageItem } from "../Utils";
import Marquee from "react-fast-marquee";

const { Header, Sider, Content } = Layout;
const ProjectLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [opennotificationDrawer, setopenNotificationDrawer] = useState(false);
  const [windowwidth, setwindowwidth] = useState(window.innerWidth);
  const heading = useSelector((state) => state.admin.heading);
  const selectedTab = useSelector((state) => state.admin.selectedTab);
  const walletBal = useSelector((state) => state.admin.walletBal);
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const handleResize = () => {
      setwindowwidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // setselectedTab(getLocalStorageItem("selectedTab"));
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    Getnews(`?page=${1}&limit=10`)
      .then((res) => {
        if (res.status) {
          setNews(res.data);
        }
      })
      .catch((err) => console.log(err));
    FetchWallet()
      .then((res) => {
        if (res.status) {
          dispatch(setWalletBal(res.walletBalance));
        }
      })
      .catch((err) => console.log(err));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);
  useEffect(() => {
    if (windowwidth > 1000) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [windowwidth]);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearAllRedux());
    navigate("/");
  };
  const items = [
    {
      key: "3",
      label: (
        <div className="w-48">
          <div className="text-sm flex justify-start items-center">
            <AiOutlineWallet size={20} className="mr-3 text-sm text-primary" />
            <p> Wallet Balance</p>
          </div>
          <p className="font-bold text-[16px] mb-4">Rs. {walletBal}</p>
          <hr />
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <>
          <p
            onClick={() => navigate("/change-password")}
            className="font-medium mb-4"
          >
            Change Password
          </p>
          <hr />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <p onClick={handleLogout} className="font-medium">
          Sign Out
        </p>
      ),
    },
  ];

  return (
    <>
      <Layout hasSider className="min-h-screen h-full  sidebar">
        <Sider
          style={{
            position: "fixed",
            marginBottom: "20px",
            overflowY: "scroll",
            height: "100vh",
          }}
          trigger={null}
          collapsible
          className="my-10"
          collapsed={collapsed}
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[selectedTab]}
            items={Menuitems()}
          />
        </Sider>
        <Layout className="w-full">
          <Header
            style={{
              marginLeft: collapsed ? 75 : 230,
              // background:"linear-gradient(169deg, rgb(245, 14, 14) 0%, rgb(255 255 255) 0%, rgb(33, 111, 242) 100%, rgb(227, 9, 9) 100%)"
            }}
            className=" bg-white mt-4 flex justify-between items-center h-20"
          >
            <div className=" flex flex-wrap justify-start gap-x-3 mx-4  items-center">
              {collapsed ? (
                <p>
                  {" "}
                  <BsArrowRight
                    size={30}
                    onClick={() => setCollapsed(!collapsed)}
                    className="text-primary cursor-pointer"
                  />
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="flex justify-center items-center gap-x-4">
              <p className="font-bold capitalize cursor-pointer ">
                Hello, {getLocalStorageItem("userDetails")?.role}
              </p>{" "}
              <p
                onClick={() => setopenNotificationDrawer(true)}
                className="bg-gray-300 p-4 cursor-pointer rounded-full"
              >
                <FaBell />
              </p>{" "}
              <Dropdown
                className="dropdownMenu cursor-pointer mr-12"
                menu={{ items }}
                trigger={["click"]}
              >
                <p className="bg-gray-300 p-4 rounded-full">
                  <FaUserLarge />
                </p>
              </Dropdown>
            </div>
            {/* </div> */}
          </Header>
          <Content
            className={`bg-background h-full p-3 md:p-5 ${
              collapsed ? "ml-[75px]" : "ml-[230px]"
            }`}
          >
            {news.filter((i)=>i.is_active).length !== 0 && (
              <div className="bg-primary rounded-sm w-full ">
                <Marquee pauseOnHover={true} speed={50}>
                  <p>&nbsp; &nbsp;</p>
                  {news.filter((i)=>i.is_active).map((i, index) => {
                    return (
                      <p
                        key={0}
                        className="py-1  rounded-md text-white  text-[17px]"
                      >
                        &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{index + 1}.{" "}
                        <span className="capitalize font-bold">
                          {i.heading}
                        </span>
                        : <span className="">{i.description}</span>
                      </p>
                    );
                  })}
                </Marquee>
              </div>
            )}
            <div className="bg-primary my-4 p-4 text-white font-bold rounded-sm">
              <p>{heading}</p>
            </div>
            {children}
          </Content>
        </Layout>
      </Layout>
      {opennotificationDrawer && (
        <NotificationDrawer
          open={opennotificationDrawer}
          setopen={setopenNotificationDrawer}
        />
      )}
    </>
  );
};

export default ProjectLayout;

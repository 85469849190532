import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { Pagination, Table } from "antd";
import { GetWalletTxns } from "../../../ApiServices/Apifun";

const WalletTxn = () => {
  const location = useLocation();
  console.log({location})
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [current, setCurrent] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const getwalletData = () => {
    setLoading(true)
    GetWalletTxns(
      `?userId=${location.state["id"]}&page=${current}&limit=10`
    )
      .then((res) => {
        if (res.status) {
          setDataSource(res.data);
          setTotalPages(res.totalPages)
        }
      })
      .catch((err) => console.log(err)).finally(()=>setLoading(false))
  };
  useEffect(() => {
    dispatch(setselectedTab("2"));
    dispatch(setHeading("Wallet Transactions"));
    getwalletData();
  }, [current]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: () => <p>{location.state.name}</p>,
    },
    {
      title: "Amount Type",
      dataIndex: "amount_type",
      key: "amount_type",
      render: (text) => (
        <div className="flex justify-start gap-2 items-center">
          <div
            className={`w-4 h-4 ${
              text === "cr" ? "bg-green-600" : "bg-red-700"
            } rounded-md`}
          ></div>
          <a className={`${text === "cr" ? "text-green-600" : "text-red-700"}`}>
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <p>{text ?? "N/A"} Rs.</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Balance Before",
      dataIndex: "balance_before",
      key: "balance_before",
      render: (text) => <p>{text ?? "N/A"} Rs.</p>,
    },
    {
      title: "Balance after",
      dataIndex: "balance_after",
      key: "balance_after",
      render: (text) => <p>{text} Rs.</p>,
    },
    {
      title: "Transactio type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => <p>{text.replace("_"," ")}</p>,
    },
    {
      title: "Withdrawable?",
      dataIndex: "is_withdrawable",
      key: "is_withdrawable",
      render: (text) => <p>{text ? "Yes" : "No"}</p>,
    },
  ];

  return (
    <>
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={columns}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={dataSource}
        />
        <div className="mt-2 mb-8 flex justify-end items-center">
          <Pagination
            defaultCurrent={current}
            current={current}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
      </div>
    </>
  );
};

export default WalletTxn;

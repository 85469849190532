import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { Button, Table } from "antd";
import {
  GetDealterList,
  GetdistributorList,
  GetuserList,
} from "../../../ApiServices/Apifun";
import { useNavigate, useParams } from "react-router-dom";
import CommonPagination from "../../../Utils/Pagination";
import { FaHistory } from "react-icons/fa";
// import { getLocalStorageItem } from "../../../Utils";

const ShowUnderPeople = () => {
  // const profileDetail = getLocalStorageItem("userDetails");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type, id } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    dispatch(setselectedTab("1001"));
    dispatch(setHeading("Settlemet"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getListData = async () => {
    setLoading(true);
    try {
      let res;
      if (type === "distributor") {
        res = await GetdistributorList(
          `?sum=true&page=${currentPage}&limit=${pageSize}`
        );
      } else if (type === "dealer") {
        res = await GetDealterList(
          `?sum=true&distributorId=${id}&page=${currentPage}&limit=10`
        );
      } else if (type === "user") {
        res = await GetuserList(`?dealerId=${id}&page=${currentPage}&limit=10`);
      }

      if (res.status) {
        setCurrent(res.page);
        setTotalPages(res.total);
        setDataSource(
          res.data.map((i, index) => {
            return {
              id: i["_id"],
              sno: index + 1,
              name: i.name,
              email: i.email,
              mobile: i.mobile,
              total_amount:i.total_amount,
              role: i.role,
              status: i.status,
              wallet_balance: i.wallet_balance,
              loginId: i.loginId,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // if (profileDetail.role !== "admin") {
    //   navigate(-1);
    // } else {
      getListData();
    // }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, navigate]);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Balance",
      dataIndex: "wallet_balance",
      key: "wallet_balance",
      render: (text) => <p>{text} Rs.</p>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text ? (
          <p className="bg-green-700 p-1 text-center w-[100px] text-white">
            Active
          </p>
        ) : (
          <p className="p-1 text-center text-white w-[100px] bg-red-800">
            In Active
          </p>
        );
      },
    },
    {
      title: "History",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <Button
          className={`bg-primary px-4  text-white text-center  `}
          onClick={() => {
            const temp =
              type === "distributor"
                ? "dealer"
                : type === "dealer"
                ? "user"
                : "";
            navigate(`/settlement-history/${temp}/${record.id}`);
          }}
        >
          <FaHistory />
        </Button>
      ),
    },
    {
      title: "Settlement Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text,record) => <p>{text ? text : 0} Rs.</p>
    },

    {
      title: "Show",
      dataIndex: "show",
      key: "show",
      render: (text, record) => (
        <button
          className="bg-primary px-4 capitalize text-white text-center cursor-pointer"
          onClick={() => {
            const temp =
              type === "distributor"
                ? "dealer"
                : type === "dealer"
                ? "user"
                : "";
            navigate(`/settlement/${temp}/${record.id}`);
          }}
        >
          show{" "}
          {type === "distributor" ? "dealer" : type === "dealer" ? "user" : ""}
        </button>
      ),
    },
  ];
  // const AdminColumns = [
  //   {
  //     title: "Sr. No.",
  //     dataIndex: "sno",
  //     key: "sno",
  //   },
  //   {
  //     title: "Type",
  //     dataIndex: "name",
  //     key: "name",
  //     render: () => <p className="capitalize">{profileDetail.role}</p>,
  //   },
  //   {
  //     title: "History",
  //     dataIndex: "",
  //     key: "",
  //     render: (text, record) => (
  //       <Button
  //         className={`bg-primary px-4  text-white text-center  `}
  //         onClick={() => {
  //           navigate(`/settlement-history/admin`);
  //         }}
  //       >
  //         <FaHistory />
  //       </Button>
  //     ),
  //   },
  //   {
  //     title: "Show",
  //     dataIndex: "show",
  //     key: "show",
  //     render: (text, record) => (
  //       <button
  //         className="bg-primary px-4 capitalize text-white text-center cursor-pointer"
  //         onClick={() => {
  //           navigate(`/settlement/distributor`);
  //         }}
  //       >
  //         show distributor
  //       </button>
  //     ),
  //   },
  // ];
  const getColumn = () => {
    let data = [...columns];
    if (type === "user") {
      const remove = data.slice(0, data.length - 3);

      return remove;
    }
    return columns;
  };
  return (
    <>
      {/* {profileDetail.role === "admin" ? ( */}
        <div className="mt-4">
          {type !== "admin" ? (
           <>
            <Table
              className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
              columns={getColumn()}
              loading={loading}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  className: "cursor-pointer text-[12px]",
                };
              }}
              dataSource={dataSource}
            />
            <div className="mt-2 mb-8 flex justify-end items-center">
            <CommonPagination
              totalPages={totalPages}
              current={currentPage}
              pageSize={pageSize}
              sizeChanger={false}
              setCurrent={setCurrent}
              setPageSize={setPageSize}
            />
          </div></>
          ) : (
            // <Table
            //   className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
            //   columns={AdminColumns}
            //   loading={loading}
            //   pagination={false}
            //   onRow={(record, rowIndex) => {
            //     return {
            //       className: "cursor-pointer text-[12px]",
            //     };
            //   }}
            //   dataSource={[{ sno: 1 }]}
            // />
            <></>
          )}
         
        </div>
      {/* ) : (
        <></>
      )} */}
    </>
  );
};

export default ShowUnderPeople;

import React, { useEffect, useState } from "react";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { useDispatch } from "react-redux";
import { FaUsers } from "react-icons/fa";
import { Skeleton } from "antd";
import { getDashboardData } from "../../../ApiServices/Apifun";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(false);
  const getDashboardCount = () => {
    setShowSkeleton(true);
    getDashboardData()
      .then((res) => {
        if (res.status) {
          setCount(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setShowSkeleton(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("1"));
    dispatch(setHeading("Dashboard"));
    getDashboardCount();
     //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* <Skeleton.Button active={true} width={30} size={250} shape={"default"} block={false} /> */}
      {showSkeleton ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 place-items-center">
          {[1, 2, 3, 4, 5, 6].map((i,index) => (
            <div key={index} className="  h-[100px]   w-full">
              <Skeleton.Button
                active={true}
                size={200}
                shape={"default"}
                block={false}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 place-items-center">
          {Object.keys(count).map((key, index) => {
            return (
              <div
                key={index}
                className=" shadow-lg hover:bg-gray-400 transition-all cursor-pointer shadow-gray-300 bg-white p-4 w-full"
              >
                <p className="flex justify-center items-center font-bold text-xl text-primary">
                  {count[key]}
                </p>
                <div className="flex text-lg mt-4 justify-between items-center">
                  <p className="font-semibold capitalize">
                    {key.split(/(?=[A-Z])/).join(" ")}
                  </p>
                  <FaUsers />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Dashboard;

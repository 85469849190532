import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading } from "../../../Store/B2bslice";
import { Button, Form, Input } from "antd";
import { FaUnlockAlt } from "react-icons/fa";
import { changeAdminPassword } from "../../../ApiServices/Apifun";
import { dispatchtoast } from "../../../Utils";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { form } = Form.useForm();
  const formref = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  useEffect(() => {
    dispatch(setHeading("ChangePassword"));
  }, []);
  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("password")) {
      return Promise.reject("The passwords do not match");
    }
    return Promise.resolve();
  };
  const handleUpdate = (val) => {
    setBtnLoading(true);
    const payload = {
      old_password: val.old_password,
      password: val.password,
    };
    changeAdminPassword(payload)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex justify-center items-center">
        <div className=" shadow-xl shadow-gray-500 w-[500px] ">
          <div className="bg-primary h-[100px] flex justify-around items-center text-white">
            <p>
              <FaUnlockAlt size={30} />
            </p>
            <p className="text-lg font-bold">Change Password</p>
          </div>
          <div className="p-4">
            <Form
              ref={formref}
              form={form}
              onFinish={(val) => handleUpdate(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter old password",
                  },
                ]}
                label="Old Password"
                name={"old_password"}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Old password"/>
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter new password",
                  },
                ]}
                label="New Password"
                name={"password"}
                labelCol={{ span: 24 }}
              >
                <Input name={"password"} placeholder="New password"/>
              </Form.Item>
              <Form.Item
                dependencies={["password"]}
                label="Confirm Password"
                name={"confirm_password"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please confirm new password",
                  },
                  { validator: validatePassword },
                ]}
              >
                <Input name="confirm_password" placeholder="Confirm password"/>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={btnLoading}
                  className="hover:border-none w-full hover:outline-none bg-btnColor"
                  style={{ color: "white" }}
                  htmlType="submit"
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

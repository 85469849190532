import { Pagination } from 'antd'
import React from 'react'

const CommonPagination = ({pageSize,current,setCurrent,sizeChanger=true,setPageSize,totalPages}) => {
  return (
    <Pagination
    defaultPageSize={pageSize}
    showQuickJumper={false}
    defaultCurrent={current}
    current={current}
    showSizeChanger={sizeChanger}
    onShowSizeChange={(a, b) => setPageSize(b)}
    total={totalPages}
    onChange={(page, pageString) => setCurrent(page)}
  />
  )
}

export default CommonPagination
import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getLocalStorageItem } from "../../../Utils";
import {
  GetDealterList,
  GetdistributorList,
  GetuserList,
} from "../../../ApiServices/Apifun";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import AddModal from "./AddModal";
import { BsFillPencilFill } from "react-icons/bs";
import UpdateModal from "./UpdateModal";
import { AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FaMoneyBill, FaUser } from "react-icons/fa6";
import UpdateWallet from "./UpdateWallet";

const UnderPeople = () => {
  const profileDetail = getLocalStorageItem("userDetails");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [current, setCurrent] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const[OpenupdateModal,setOpenUpdateModal]=useState(false)
  const[openUpdateWallet,setOpenUpdateWallet]=useState(false)

  const[rowData,setRowData]=useState(null)
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const getListData = async () => {
    setLoading(true);
    try {
      let res;
      if (profileDetail.role === "admin") {
        res = await GetdistributorList(`?page=${current}&limit=10`);
      } else if (profileDetail.role === "distributor") {
        res = await GetDealterList(
          `?distributorId=${profileDetail.userId}&page=${current}&limit=10`
        );
      } else if (profileDetail.role === "dealer") {
        res = await GetuserList(
          `?dealerId=${profileDetail.userId}&page=${current}&limit=10`
        );
      }
      if (res.status) {
        setCurrent(res.page);
        setTotalPages(res.total);
        setDataSource(
          res.data.map((i, index) => {
            return {
              id:i["_id"],
              sno: index + 1,
              name: i.name,
              email: i.email,
              mobile: i.mobile,
              role: i.role,
              status: i.status,
              wallet_balance: i.wallet_balance,
              loginId: i.loginId,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(setselectedTab("2"));
    dispatch(
      setHeading(
        profileDetail.role === "admin"|| profileDetail.role === "sub_admin"
          ? "Distributors"
          : profileDetail.role === "distributor"
          ? "Dealer"
          : profileDetail.role === "dealer"
          ? "Users"
          : ""
      )
    );
    getListData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Balance",
      dataIndex: "wallet_balance",
      key: "wallet_balance",
      render: (text) => <p>{text} Rs.</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text ? (
          <p className="bg-green-700 p-1 text-center w-[100px] text-white">Active</p>
        ) : (
          <p className="p-1 text-center text-white w-[100px] bg-red-800">In Active</p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (text,record) => (
        <p onClick={()=>{
          setOpenUpdateModal(true)
          setRowData(record)
        }}>
          <BsFillPencilFill  className="bg-primary text-white flex justify-center items-center p-2" size={25}/>
        </p>
      ),
    },
    {
      title: "View Txn",
      dataIndex: "txn.",
      key: "txn.",
      render: (text,record) => (
        <p onClick={()=>{
          navigate("/wallet-txn",{state:record})
        }}>
          <AiFillEye  className="bg-primary text-white flex justify-center items-center p-2" size={25}/>
        </p>
      ),
    },{
      title: "Cr/Dr wallet",
      dataIndex: ".",
      key: ".",
      render: (text,record) => (
        <p  onClick={()=>{
          setOpenUpdateWallet(true)
          setRowData(record)
        }}>
          <FaMoneyBill color="green"  className="bg-primary text-white flex justify-center items-center p-2" size={25}/>
        </p>
      ),
    },
    {
      title: profileDetail.role==="admin" ||profileDetail.role==="sub_admin" ?"Show Dealer":profileDetail.role==="distributor"?"Show User":null,
      dataIndex: ".",
      key: ".",
      render: (text,record) => (
        <p className="" onClick={()=>{
          navigate("/under-admin",{state:record})
        }}>
          <FaUser  className="bg-primary text-white flex justify-center items-center p-2" size={25}/>
        </p>
      ),
    },
  ];
  const getColumn = () => {
    let data = [...columns];
    if ( profileDetail.role === "dealer") {
      const remove = data.slice(0, data.length - 1);

      return remove;
    }
    return columns;
  };
  return (
    <>
      <div className="flex justify-end items-center">
        <p
          onClick={() => setOpenModal(true)}
          className="bg-primary text-white p-2 rounded-sm hover:bg-primaryhover cursor-pointer"
        >
          Add { profileDetail.role === "admin"
          ? "Distributors"
          : profileDetail.role === "distributor"
          ? "Dealer"
          : profileDetail.role === "dealer"
          ? "Users"
          : ""}
        </p>
      </div>
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={getColumn()}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={dataSource}
        />
        <div className="mt-2 mb-8 flex justify-end items-center">
          <Pagination
            showQuickJumper={false}
            defaultCurrent={current}
            current={current}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
      </div>
      {openModal && (
        <AddModal
          apiCall={getListData}
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
      {OpenupdateModal && (
        <UpdateModal
          apiCall={getListData}
          open={OpenupdateModal}
          record={rowData}
          setOpen={setOpenUpdateModal}
        />
      )}
      {openUpdateWallet && (
        <UpdateWallet
          apiCall={getListData}
          open={openUpdateWallet}
          record={rowData}
          setOpen={setOpenUpdateWallet}
        />
      )}
    </>
  );
};

export default UnderPeople;

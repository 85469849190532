import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { Button, Input, Select, Spin, Table } from "antd";
import { GetWalletReport } from "../../../ApiServices/Apifun";
import CommonPagination from "../../../Utils/Pagination";
import { AiOutlineSearch } from "react-icons/ai";
import { getLocalStorageItem } from "../../../Utils";
import { useNavigate } from "react-router-dom";

const WalletReport = () => {
  const profileDetail = getLocalStorageItem("userDetails");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [searchcolumn, setSearchColumn] = useState(null);
const[searchKeyword,setSearchKeyWord]=useState("")
  const [data, setData] = useState([]);
  useEffect(() => {
    if (profileDetail.role === "sub_admin") {
      if (!getLocalStorageItem("services").includes(6)) {
        navigate("/not-available");
        dispatch(setselectedTab("9"));
      }
    }
    dispatch(setselectedTab("9"));
    dispatch(setHeading("Wallet Report"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ReportColumn = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <p className="font-medium w-[150px]">
          {new Date(`${text}`).toDateString()}
        </p>
      ),
    },
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
      render: (text) => {
        return (
          <>
            <p className=" font-medium  overflow-y-scroll">{text}</p>
          </>
        );
      },
    },

    {
      title: "Comission Id",
      dataIndex: "commision_id",
      key: "commision_id",
      render: (text) => <p className=" font-medium">{text}</p>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <p style={{ width: "80px" }} className=" font-medium">
          {text} Rs.
        </p>
      ),
    },
    {
      title: "Amount Type",
      dataIndex: "amount_type",
      key: "amount_type",
      render: (text) => (
        <p style={{ width: "120px" }} className="text-center font-medium">
          {text}
        </p>
      ),
    },
    {
      title: "Balance Before",
      dataIndex: "balance_before",
      key: "balance_before",
      render: (text) => (
        <p style={{ width: "80px" }} className=" font-medium">
          {text}
        </p>
      ),
    },
    {
      title: "Balance After",
      dataIndex: "balance_after",
      key: "balance_after",
      render: (text) => (
        <p style={{ width: "80px" }} className=" font-medium">
          {text}
        </p>
      ),
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => <p className=" font-medium">{text}</p>,
    },
  ];
  const getReport = (
    column=searchcolumn,
    key=searchKeyword
  ) => {
    setPageLoading(true);
    GetWalletReport(`?page=${currentPage}&limit=${pageSize}&${column}=${key}`)
      .then((res) => {
        if (res.status) {
          setData(res.transactions);
          setTotalPages(res.totalDocuments);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoading(false));
  };
  useEffect(() => {
    getReport();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  return (
    <>
      <div>
        <div className="prepaid  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
          <div className="flex justify-between items-center">
            <p className="font-bold text-[15px] mb-2">Wallet Report</p>
            <p onClick={()=>{
              setSearchColumn(null)
              setSearchKeyWord("")
              getReport("","")
            }} className="font-medium text-xs cursor-pointer bg-bsred bg-red-600 text-white px-2 py-1 rounded-md text-[15px] mb-2">
              Clear Filter
            </p>
          </div>
          <div className="selectionFields grid grid-cols-1 md:grid-cols-3 gap-4 place-items-center ">
            <div className="w-full">
              <p className={`required text-textGray mt-2`}>
                Select Search Type
              </p>
              <Select
                placeholder="Select search type"
                value={searchcolumn}
                onChange={(val) => setSearchColumn(val)}
                className="w-full mt-2"
                options={[
                  {
                    label: "Amount Type",
                    value: "amount_type",
                  },
                  {
                    label: "Transaction  Type",
                    value: "transaction_type",
                  },
                  // {
                  //   label: "User Id",
                  //   value: "user_id",
                  // },
                ]}
              />
            </div>
            <div className="w-full">
              <p className={`required text-textGray `}>Keyword</p>
              {searchcolumn === "wallet.transaction_type" ? (
                <Select
                  placeholder="Select search type"
                  value={searchcolumn}
                  onChange={(val) => setSearchColumn(val)}
                  className="w-full mt-2"
                  options={[
                    {
                      label: "Amount Type",
                      value: "amount_type",
                    },
                    {
                      label: "Transaction  Type",
                      value: "transaction_type",
                    },
                    {
                      label: "User Id",
                      value: "user_id",
                    },
                  ]}
                />
              ) : (
                <Input
                  placeholder="Seacrh"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyWord(e.target.value)}
                  className="outline-none text-xs border mt-4 w-full  border-gray-200 focus:border-primary   px-2 "
                />
              )}
            </div>
          </div>
          <div className="w-full flex justify-end items-center mt-3">
            <Button
              // disabled=
              onClick={() => getReport()}
              style={{ color: "white" }}
              className=" font-semibold flex justify-between gap-2 items-center hover:bg-primaryhover bg-primary border-none"
            >
              <AiOutlineSearch />
              <p>Search</p>
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <Spin spinning={pageLoading}>
            <Table
              className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
              columns={ReportColumn}
              pagination={false}
              dataSource={data}
            />
            <div className="mt-2 mb-8 flex justify-end items-center">
              <CommonPagination
                totalPages={totalPages}
                current={currentPage}
                pageSize={pageSize}
                setCurrent={setCurrent}
                setPageSize={setPageSize}
              />
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default WalletReport;

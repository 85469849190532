import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";

const ColorProfileModal = ({ open, setOpen, object }) => {
  const [filteredObj, setFilterObj] = useState({});
  useEffect(() => {
    const data = {
      Name: object?.name??"NA",
      Mobile: object?.mobile??"-",
      Email: object?.email??"-",
      isVerified: object?.isVerified?"Yes":"No",
      "Wallet Balance": object?.wallet_balance??"-",
    };
    setFilterObj(data);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Modal
        footer={null}
        title="Profile Modal"
        open={open}
        onCancel={() => setOpen(false)}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 place-items-center">
          {/* <Skeleton.Avatar
            className="w-full"
            active={true}
          
            size={150}
            shape={"circle"}
          /> */}
          <div className="bg-gray-300 flex justify-center items-center w-28 h-28 rounded-full">
            <p className="font-bold">No Image</p>
          </div>
          <div className="grid grid-cols-2  place-items-center gap-4">
            {Object.keys(filteredObj).map((key,index) => {
              return (
                <div key={index} className="w-full">
                  <p className="font-bold">{key}:</p>
                  <p className="font-semibold">{filteredObj[key]}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex justify-end items-center">
            <Button onClick={()=>setOpen(false)} className="bg-btnColor outline-none hover:outline-none hover:border-none focus:outline-none " style={{color:"white"}}>Ok</Button>
        </div>
      </Modal>
    </>
  );
};

export default ColorProfileModal;

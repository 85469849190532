import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {  Pagination, Table, Tooltip } from "antd";
import { RoomDetailById } from "../ApiServices/Apifun";
import { useLocation, useNavigate } from "react-router-dom";
import { setHeading, setselectedTab } from "../Store/B2bslice";
import { FaUser } from "react-icons/fa";

const CommonRoomDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { endpoint, from, id } = location?.state || {};
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [current, setCurrent] = useState(1);
  const [dataSource, setDataSource] = useState([]);

  const getListData = async () => {
    setLoading(true);
    RoomDetailById(endpoint, `?roomId=${id}&page=${1}&limit=10`)
      .then((res) => {
        if (res.status) {
          setCurrent(res.pagination.currentPage);
          setTotalPages(res.pagination.totalDocuments);
          setDataSource(
            res.data.map((i, index) => {
              return { ...i, sno: index + 1 };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {}, []);
  useEffect(() => {
    if (!location.state) {
      return navigate(-1);
    } else {
      dispatch(setselectedTab("11"));
      dispatch(setHeading("Room Detail"));
      getListData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Room Id",
      dataIndex: "room_id",
      key: "room_id",
      render: (text) => <p>{text} Rs.</p>,
    },
    {
      title: "Plateform Service Amount",
      dataIndex: "platform_service_amt",
      key: "platform_service_amt",
      render: (text) => <p>{text ?? "N/A"} Rs.</p>,
    },
    {
      title: "Choose option id",
      dataIndex: "choose_option_id",
      key: "choose_option_id",
      render: (text, record) => {
        return from === "color" ? (
          <div className="flex justify-center items-center">
            <p className="bg-green-700 text-center text-white rounded-full p-1 w-8 h-8">
              {text}
            </p>
          </div>
        ) : (
          <div className="flex justify-start items-center gap-x-2 flex-wrap">
            {record.choose_option_id.map((i,index) => (
              <p key={index} className="bg-green-700 text-center text-white rounded-full p-1 w-8 h-8">
                {i}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      title: "Bet Amount",
      dataIndex: "bet_amount",
      key: "bet_amount",
      render: (text) => <p>{text} Rs.</p>,
    },
    {
      title: "Winning Amount",
      dataIndex: "winning_amt",
      key: "winning_amt",
      render: (text) => {
        return <p className="p-1 text-center">{text}</p>;
      },
    },
    {
      title: "User Detail",
      dataIndex: "",
      key: "",
      render: (text, record) => {
        return (
          <Tooltip
            placement="left"
            title={
              <div>
                <p>Email: {record.UserDetails[0].email ?? ""}</p>
                <p>Mobile: {record.UserDetails[0].mobile ?? ""}</p>
                <p>Name: {record.UserDetails[0].name ?? ""}</p>
              </div>
            }
          >
            <div className=" bg-primary  flex justify-center items-center rounded-full p-2">
              <FaUser color="white" />
            </div>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <>
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={columns}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={dataSource}
        />
        <div className="mt-2 mb-8 flex justify-end items-center">
          <Pagination
            showQuickJumper={false}
            defaultCurrent={current}
            current={current}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
      </div>
    </>
  );
};

export default CommonRoomDetail;

import { Button, Form, Input, Table, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";


import { IoCloudUploadOutline } from "react-icons/io5";
import { useForm } from "antd/es/form/Form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BankList, DoFundReq } from "../../../ApiServices/Apifun";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";

const FundRequest = () => {
  const profileDetail = getLocalStorageItem("userDetails");
  const navigate = useNavigate();
  const formref = useRef();
  const dispatch = useDispatch();
  const [form] = useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [image, setImage] = useState([]);
  const [binaryImage, setBinaryImage] = useState(null);
  const [data, setData] = useState([]);
  const getBank = () => {
    BankList()
      .then((res) => {
        if (res.status) {
          setData([res.data]);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (profileDetail.role === "admin") {
      return navigate(-1);
    }else if (profileDetail.role === "sub_admin") {
      if (!getLocalStorageItem("services").includes(1)) {
        navigate("/not-available");
        dispatch(setselectedTab("4"));
      }
    }
    dispatch(setselectedTab("4"));
    getBank();
    dispatch(setHeading("Fund Request"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinish = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("amount", val.amount);
    formdata.append("image", binaryImage);
    DoFundReq(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setImage([]);
          setBinaryImage(null);
          formref.current.setFieldsValue({
            amount: "",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleCustomReq = async ({ file, onSuccess, onError }) => {
    try {
      setBinaryImage(file);
      const base64Data = await readFileAsBase64(file);
      const dataUrl = await readFileAsDataUrl(file);
      const newFile = {
        ...file,
        name: file.name,
        thumbUrl: dataUrl,
        base64: base64Data,
      };
      setImage([newFile]);
      //   setImageBase64(base64Data);
      onSuccess();
    } catch (error) {
      console.error(error);
      onError(error);
    }
  };
  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  // Helper function to read a file as Base64 data
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result; // Remove the data URI prefix
        resolve(base64Data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const columns = [
    {
      title: "Bank Name",
      dataIndex: "bank",
      key: "bank",
      render: (text) => <p className="w-[200px]">{text}</p>,
    },
    {
      title: "Account Holder Name",
      dataIndex: "ac_holder_name",
      key: "ac_holder_name",
      render: (text) => <p className="w-[150px]">{text}</p>,
    },
    {
      title: "Account Number",
      dataIndex: "ac_no",
      key: "ac_no",
      render: (text) => <p className="w-[200px]">{text}</p>,
    },
    {
      title: "IFSC Code",
      dataIndex: "ifsc_code",
      key: "ifsc_code",
      render: (text) => <p className="w-[150px]">{text}</p>,
    },
  ];
  return (
    <>
      <div>
        <Table
          className="history-table rounded-md  overflow-x-auto text-white "
          dataSource={data}
          columns={columns}
          pagination={false}
        />
      </div>
      <div className="grid grid-cols-1 mt-4 md:grid-cols-1 gap-4 place-items-start">
        <div className="prepaid animate-slide-in w-full  bg-white shadow-normal  rounded-xl p-5">
          <p className="font-bold text-[15px] mb-2">Send Add Fund Request</p>
          <Form
            form={form}
            ref={formref}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleFinish(val)}
          >
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-8 place-items-start">
              <Form.Item
                name={`attachment`}
                label={"Payment Proof"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Upload one payment proof!",
                  },
                ]}
              >
                <Upload
                  maxCount={1}
                  onRemove={() => {
                    setImage([]);
                  }}
                  listType="picture"
                  accept=".jpg, .jpeg, .png"
                  fileList={image}
                  customRequest={handleCustomReq}
                >
                  <Button
                    className="bg-primary text-white border-none font-medium"
                    icon={<IoCloudUploadOutline />}
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                name={`amount`}
                label={"Fund Amount"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter fund amount!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <Input
                  onInput={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    e.target.value = value;
                  }}
                  placeholder="Enter Amount"
                  name="amount"
                />
              </Form.Item>{" "}
            </div>
            <div className="flex justify-end items-center ">
              <Form.Item>
                <Button
                  loading={btnLoading}
                  className="w-full bg-primary border-none mt-4"
                  style={{ color: "white" }}
                  htmlType="submit"
                >
                  Send
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default FundRequest;

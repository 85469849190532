import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  GetDealterList,
  GetuserList,
} from "../../../ApiServices/Apifun";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import AddModal from "./AddModal";
import UpdateModal from "./UpdateModal";
import { AiFillEye } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import {  FaUser } from "react-icons/fa6";
import UpdateWallet from "./UpdateWallet";

const UnderPeopleTable = () => {
  const location = useLocation();
  // const profileDetail = getLocalStorageItem("userDetails");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [current, setCurrent] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [OpenupdateModal, setOpenUpdateModal] = useState(false);
  const [openUpdateWallet, setOpenUpdateWallet] = useState(false);
// eslint-disable-next-line
  const [rowData, setRowData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getListData = async () => {
    setLoading(true);
    try {
      let res;
      if (location.state.role === "distributor") {
        res = await GetDealterList(
          `?distributorId=${location.state.id}&page=${current}&limit=10`
        );
      } else if (location.state.role === "dealer") {
        res = await GetuserList(
          `?dealerId=${location.state.id}&page=${current}&limit=10`
        );
      }
      if (res.status) {
        setCurrent(res.page);
        setTotalPages(res.total);
        setDataSource(
          res.data.map((i, index) => {
            return {
              id: i["_id"],
              sno: index + 1,
              name: i.name,
              email: i.email,
              mobile: i.mobile,
              role: i.role,
              status: i.status,
              wallet_balance: i.wallet_balance,
              loginId: i.loginId,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(setselectedTab("2"));
    dispatch(
      setHeading(
        location.state.role === "admin"|| location.state.role === "sub_admin"
          ? "Distributors"
          : location.state.role === "distributor"
          ? "Dealer"
          : location.state.role === "dealer"
          ? "Users"
          : ""
      )
    );
    getListData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, location]);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Balance",
      dataIndex: "wallet_balance",
      key: "wallet_balance",
      render: (text) => <p>{text} Rs.</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text ? (
          <p className="bg-green-700 p-1 text-center w-[100px] text-white">
            Active
          </p>
        ) : (
          <p className="p-1 text-center text-white w-[100px] bg-red-800">
            In Active
          </p>
        );
      },
    },

    {
      title: "View Txn",
      dataIndex: "txn.",
      key: "txn.",
      render: (text, record) => (
        <p
          onClick={() => {
            navigate("/wallet-txn", { state: record });
          }}
        >
          <AiFillEye
            className="bg-primary text-white flex justify-center items-center p-2"
            size={25}
          />
        </p>
      ),
    },
    {
      title:
        location.state.role === "admin"|| location.state.role === "sub_admin"
          ? "Show Dealer"
          : location.state.role === "distributor"
          ? "Show User"
          : "",
      dataIndex: ".",
      key: ".",
      render: (text, record) => (
        <p
          onClick={() => {
            navigate("/under-admin", { state: record });
            // window.location.reload();
          }}
        >
          <FaUser
            className="bg-primary text-white flex justify-center items-center p-2"
            size={25}
          />
        </p>
      ),
    },
  ];
  const getColumn = () => {
    let data = [...columns];
    if (location.state.role === "dealer") {
      const remove = data.slice(0, data.length - 1);

      return remove;
    }
    return columns;
  };
  return (
    <>
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={getColumn()}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={dataSource}
        />
        <div className="mt-2 mb-8 flex justify-end items-center">
          <Pagination
            showQuickJumper={false}
            defaultCurrent={current}
            current={current}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
      </div>
      {openModal && (
        <AddModal
          apiCall={getListData}
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
      {OpenupdateModal && (
        <UpdateModal
          apiCall={getListData}
          open={OpenupdateModal}
          record={rowData}
          setOpen={setOpenUpdateModal}
        />
      )}
      {openUpdateWallet && (
        <UpdateWallet
          apiCall={getListData}
          open={openUpdateWallet}
          record={rowData}
          setOpen={setOpenUpdateWallet}
        />
      )}
    </>
  );
};

export default UnderPeopleTable;

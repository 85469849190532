import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import ProjectLayout from "../ProjectLayout";
import { getLocalStorageItem } from "../Utils";

function ProtectedAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("accessToken");
    return user;
  };


  return isAuthenticated() ? (
    <>
      {" "}
      <ProjectLayout>
          <Outlet />
      </ProjectLayout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;

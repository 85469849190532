import { Button, Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { GivePermission, RemovePermission } from "../../../ApiServices/Apifun";
import { dispatchtoast } from "../../../Utils";

const PermissionModal = ({
  open,
  setOnlyForShow,setIsRemoving,isRemoving,
  record,
  apiCall=()=>{},
  setOpen,
  onlyForShow = false,
}) => {
  const [btnloading, setBtnLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const options = [
    {
      label: "FundRequest",
      value: 1,
    },
    {
      label: "Withdrawal Request",
      value: 2,
    },
    {
      label: "Roullete Game",
      value: 3,
    },
    {
      label: "Color Game",
      value: 4,
    },
    {
      label: "Payment Setting",
      value: 5,
    },{
      label: "Wallet",
      value: 6,
    },
    {
      label: "Fourtune game",
      value: 7,
    },
  ];
  useEffect(()=>{
setSelected(record?.permissions[0].permissionList)
//eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const handleSet = () => {
    setBtnLoading(true);
    if(!isRemoving){
      GivePermission({
        permissionPersonId: record.id,
        permissionsList: selected,
      })
        .then((res) => {
          if (res.status) {
            apiCall()
              setOpen(false)
            dispatchtoast(res.message);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setBtnLoading(false));
    }else {
      RemovePermission({
        permissionPersonId: record.id,
        removePermissionsList: options.filter((i,index)=>!selected?.includes(i.value)).map((i)=>i.value),
      }).then((res) => {
        if (res.status) {
          apiCall()
            setOpen(false)
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
    }
   
  };
  return (
    <Modal
      footer={null}
      open={open}
      onCancel={() => {
        setOpen(false);
        setOnlyForShow(false);
      }}
      title="Permission Modal"
    >
      <Checkbox.Group
        className="flex justify-start mt-4 items-center gap-4"
        options={options}
        value={selected}
        disabled={onlyForShow}
        onChange={(e) => setSelected(e)}
      />
      {!onlyForShow && (
        <div className="flex justify-end items-center gap-x-4">
          <Button>Cancel</Button>
          <Button
            onClick={handleSet}
            loading={btnloading}
            style={{ color: "white" }}
            className="bg-primary border-none hover:border-none"
          >
            Set
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default PermissionModal;

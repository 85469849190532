import { Delete, FormDataPost, Get, Post, Put, getAPIUrl } from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};

export const userLogout = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.logout, param);
  return Post(url, data, false);
};
export const changeAdminPassword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.changePassword, param);
  return Post(url, data);
};
export const GetNotifications = (type, param = "") => {
  const url = getAPIUrl(`${"user"}/get-user-notification`, param);
  return Get(url);
};
export const FetchWallet = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchWallet, param);
  return Get(url);
};
export const GetdistributorList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.distributorList, param);
  return Get(url);
};
export const GetDealterList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.dealerList, param);
  return Get(url);
};
export const GetuserList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.userList, param);
  return Get(url);
};
export const AddDistributor = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addDistributor, param);
  return Post(url, data);
};
export const AddDealer = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addDealer, param);
  return Post(url, data);
};
export const AddUser = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addUser, param);
  return Post(url, data);
};
export const updateDistributor = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateDistributor, param);
  return Put(url, data);
};
export const updateDealer = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateDealer, param);
  return Put(url, data);
};
export const updateUser = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateUser, param);
  return Post(url, data);
};
export const updateDealterToUserWallet = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updatedealertoUserWallet, param);
  return Post(url, data);
};
export const updateadmindisdeaWallet = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateadmindisdealwallet, param);
  return Post(url, data);
};
export const fetchGameSettings = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchgameSeeting, param);
  return Get(url);
};
export const updateGameSettings = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateGameSetting, param);
  return Put(url,data);
};
export const GetWalletTxns = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getWalletTxn, param);
  return Get(url);
};
export const getDashboardData = (param = "") => {
  const url = getAPIUrl(Globalendpoints.gameDashboard, param);
  return Get(url);
};
export const roulletecurrentRoomStats = (param = "") => {
  const url = getAPIUrl(Globalendpoints.roulletecurrentRoomStat, param);
  return Get(url);
};
export const currentRoomStats = (param = "") => {
  const url = getAPIUrl(Globalendpoints.currentRoomStat, param);
  return Get(url);
};
export const RoomDetailById = (endpoint,param = "") => {
  const url = getAPIUrl(endpoint, param);
  return Get(url);
};

export const AllRooms = (param = "") => {
  const url = getAPIUrl(Globalendpoints.allRooms, param);
  return Get(url);
};
export const colorAllRooms = (param = "") => {
  const url = getAPIUrl(Globalendpoints.colorAllRooms, param);
  return Get(url);
};

export const colorOption = (param = "") => {
  const url = getAPIUrl(Globalendpoints.colorOption, param);
  return Get(url);
};

export const DoFundReq = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.DofundReq, param);
  return FormDataPost(url, data);
};
export const FetchFundReq = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchFundReq, param);
  return Get(url);
};
export const FundReqAction = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.fundReqAction, param);
  return Put(url,data);
};
export const AddBank = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addBank, param);
  return Post(url,data);
};
export const BankList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchBankList, param);
  return Get(url);
};
export const FetchUSerBank = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchUserBanks, param);
  return Get(url);
};
export const updateBankDetail = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.updateBankDetail, param);
  return Put(url,data);
};

export const FetchWithdrawalReq = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchwithdrwalReq, param);
  return Get(url);
};

export const DoWithdrawalReq = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.DoWithdrawalReq, param);
  return Post(url, data);
};
export const WithdrawalReqAction = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.withdrawalReqAction, param);
  return Post(url,data);
};
export const GetWalletReport = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.getWalletReport, param);
  return Get(url);
};

export const CreateSubAdmin = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.createSubAdmin, param);
  return Post(url,data);
};
export const GetSubAdmin = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getSubAdmin, param);
  return Get(url);
};
export const updateSubAdmin = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.updateSubAdmin, param);
  return Put(url,data);
};

export const GivePermission = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.givePermission, param);
  return Post(url,data);
};
export const RemovePermission = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.removePermission, param);
  return Post(url,data);
};
export const CreateOffer = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.createOffer, param);
  return FormDataPost(url,data);
};

export const Getoffer = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchOffers, param);
  return Get(url);
};

export const RemoveOffer = (param = "") => {
  const url = getAPIUrl(Globalendpoints.removeOffer, param);
  return Delete(url);
};

export const ChooseWinner = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.chooseWinner, param);
  return Post(url,data);
};

export const forTuneChooseWinner = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.fortunechooseWinner, param);
  return Post(url,data);
};

export const colorChooseWinner = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.colorchooseWinner, param);
  return Post(url,data);
};

//FORTUNE ROOM

export const fourTuneAllRooms = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fortuneAllRooms, param);
  return Get(url);
};
export const fourTuneRoomStats = (param = "") => {
  const url = getAPIUrl(Globalendpoints.currentRoomStatFortune, param);
  return Get(url);
};

export const fourTuneOptions = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fortuneOption, param);
  return Get(url);
};

export const AddNews = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addnews, param);
  return Post(url,data);
};

export const Getnews = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getNews, param);
  return Get(url);
};
export const GetnewsUserType = (param = "") => {
  const url = getAPIUrl(Globalendpoints.newsUserType, param);
  return Get(url);
};
export const editnews = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.editNews, param);
  return Post(url,data);
};
export const updateSettlement = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.updateSettlement, param);
  return Post(url,data);
};
export const fetchSettleHistory = (param = "") => {
  const url = getAPIUrl(Globalendpoints.fetchSettleHistory, param);
  return Get(url);
};
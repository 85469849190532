import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { FaUnlockAlt } from "react-icons/fa";
import { userLogin } from "../../ApiServices/Apifun";
import { setLocalStorageItem } from "../../Utils";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate=useNavigate()
  const handleSubmit = (val) => {
    setBtnLoading(true);
    userLogin(val)
      .then((res) => {
        if (res.status) {
          setLocalStorageItem("accessToken", res.access_token);
          setLocalStorageItem("refreshToken", res.referesh_token);
          setLocalStorageItem("userDetails", res.user);
          setLocalStorageItem("services",res.serviceList)
          navigate("/dashboard")
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex bg-background h-screen justify-center items-center">
        <div className="shadow-xl shadow-gray-500 w-[300px]">
          <div className="bg-primary h-[100px] flex justify-around items-center text-white">
            <p>
              <FaUnlockAlt size={30} />
            </p>
            <p className="text-lg font-bold">Login</p>
          </div>
          <div className="p-4">
            <Form
              onFinishFailed={(err) => console.log(err)}
              onFinish={(val) => handleSubmit(val)}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter login I'd",
                  },
                ]}
                className="w-full"
                name={"loginId"}
              >
                <Input className="mt-2" placeholder="Enter login I'd" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
                name={"password"}
              >
                <Input className="mt-2" placeholder="Enter password" />
              </Form.Item>
              <Form.Item className="w-full" name={"password"}>
                <Button
                  loading={btnLoading}
                  style={{ color: "white" }}
                  className="w-full mt-4 hover:border-none focus:border-none bg-btnColor"
                  htmlType="submit"
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;


import Login from "../Screens/Auth/Login";
import PublicAuth from "./PublicAuth";

export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        
      ],
    },
  ];
};

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { Button, Form, Input, Select, Spin } from "antd";
import {
  fetchGameSettings,
  updateGameSettings,
} from "../../../ApiServices/Apifun";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";
import { useLocation, useNavigate } from "react-router-dom";

const FourtuneGamesetting = () => {
  const profileDetail = getLocalStorageItem("userDetails");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { from } = location.state || {};
  const [form] = Form.useForm();
  const formref = useRef();
  const [gameSlug, setGameSlug] = useState("");
  const [gameId, setGameId] = useState("");
  const [loader, setpageLoadr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  useEffect(() => {
    if (!location.state) {
      navigate(-1);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getgamedata = () => {
    setpageLoadr(true);
    fetchGameSettings()
      .then((res) => {
        if (res.status) {
          const filterObj = res.data.find((i) => i.game_slug === from);
          setGameSlug(filterObj.game_slug);
          setGameId(filterObj["_id"]);
          const {
            room_timimer,
            bet_close_timer,
            multibet_status,
            min_betting_amount,
            referral_bet_commission_status,
            referral_bet_commission_level,
            platform_charges,
            platform_charges_type,
          } = filterObj.setting;
          formref.current.setFieldsValue({
            game_name: filterObj.game_name,
            game_status: filterObj.game_status,
            room_timimer,
            bet_close_timer,
            multibet_status,
            min_betting_amount,
            referral_bet_commission_status,
            referral_bet_commission_level,
            platform_charges,
            platform_charges_type,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setpageLoadr(false));
  };
  useEffect(() => {
    if (profileDetail.role === "sub_admin") {
      if (!getLocalStorageItem("services").includes(7)) {
        navigate("/not-available");
        dispatch(setselectedTab("12.1"));
      }
    }
    dispatch(setselectedTab("12.1"));
    dispatch(setHeading("Fourtune Setting"));
    getgamedata();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleUpdate = (val) => {
    const {
      room_timimer,
      bet_close_timer,
      multibet_status,
      min_betting_amount,
      referral_bet_commission_status,
      referral_bet_commission_level,
      platform_charges,
      platform_charges_type,
    } = val;
    setBtnLoading(true);
    const payload = {
      name: gameSlug,
      status: val.game_status,
      setting: {
        room_timimer: Number(room_timimer),
        bet_close_timer: Number(bet_close_timer),
        multibet_status,
        min_betting_amount,
        referral_bet_commission_status,
        referral_bet_commission_level,
        platform_charges,
        platform_charges_type,
      },
    };
    updateGameSettings(payload, `/${gameId}`)
      .then((res) => {
        if (res.status) {
          getgamedata();
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <Spin spinning={loader}>
        <div className="bg-white rounded-sm p-3">
          <Form
            ref={formref}
            form={form}
            onFinish={(val) => handleUpdate(val)}
            onFinishFailed={(err) => console.log(err)}
          >
            <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 place-items-center gap-2">
              <Form.Item
                name={"game_name"}
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
                className="w-full"
                label="Name"
                labelCol={{ span: 24 }}
              >
                <Input disabled placeholder="Name" />
              </Form.Item>
              <Form.Item
                name={"game_status"}
                className="w-full"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Please select status",
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <Select
                  options={[
                    {
                      label: "Activate",
                      value: true,
                    },
                    {
                      label: "Deactivate",
                      value: false,
                    },
                  ]}
                  placeholder="Status"
                />
              </Form.Item>{" "}
              <Form.Item
                name={"room_timimer"}
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please enter room timer",
                  },
                ]}
                label="Room Timimer"
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Room Timer" />
              </Form.Item>{" "}
              <Form.Item
                name={"bet_close_timer"}
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please enter bet close timer",
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Only numbers are allowed",
                  },
                ]}
                label="Bet Close Timer"
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Bet Close Timer" />
              </Form.Item>{" "}
              <Form.Item
                name={"multibet_status"}
                className="w-full"
                label="Multibet Status"
                rules={[
                  {
                    required: true,
                    message: "Please select multibet status",
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <Select
                  options={[
                    {
                      label: "Activate",
                      value: true,
                    },
                    {
                      label: "Deactivate",
                      value: false,
                    },
                  ]}
                  placeholder="Multibet Status"
                />
              </Form.Item>{" "}
              <Form.Item
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please enter mini bet amount",
                  },
                ]}
                name={"min_betting_amount"}
                label="Min Betting Amount"
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Min Betting Amount" />
              </Form.Item>
              {/* <Form.Item
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please select referral bet commission status",
                  },
                ]}
                name={"referral_bet_commission_status"}
                label="Referral Bet Commission Status"
                labelCol={{ span: 24 }}
              >
                <Select
                  options={[
                    {
                      label: "Activate",
                      value: true,
                    },
                    {
                      label: "Deactivate",
                      value: false,
                    },
                  ]}
                  placeholder="Referral Bet Commission Status"
                />
              </Form.Item>{" "}
              <Form.Item
                name={"referral_bet_commission_level"}
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please enter referral bet commission level",
                  },
                ]}
                label="Referral Bet Commission Level"
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Referral Bet Commission Level" />
              </Form.Item>{" "} */}
              <Form.Item
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please select plateform charge type",
                  },
                ]}
                name={"platform_charges_type"}
                label="Platform Charges Type"
                labelCol={{ span: 24 }}
              >
                <Select
                  options={[
                    {
                      label: "%",
                      value: "%",
                    },
                    {
                      label: "₹",
                      value: "₹",
                    },
                  ]}
                  placeholder="Platform Charges Type"
                />
              </Form.Item>{" "}
              <Form.Item
                name={"platform_charges"}
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please enter plateform charges",
                  },
                ]}
                label="Platform Charges"
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Platform Charges" />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex justify-end mt-2 items-center gap-4">
                <Button
                  onClick={() => getgamedata()}
                  style={{ color: "white" }}
                  className="border border-none hover:border-none focus:border-none bg-blue-600 text-white"
                >
                  Reset
                </Button>
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  style={{ color: "white" }}
                  className="border border-none hover:border-none focus:border-none bg-btnColor text-white"
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default FourtuneGamesetting;

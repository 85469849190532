import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import {
  dispatchtoast,
  getLocalStorageItem,
  mobilePattern,
} from "../../../Utils";
import {
  CreateSubAdmin,
} from "../../../ApiServices/Apifun";

const AddSubAdmin = ({ open, setOpen, apiCall = () => {} }) => {
  const Role = getLocalStorageItem("userDetails").role;
  const [btnLoading, setBtnLoading] = useState(false);

  const handleAdd = (val) => {
    setBtnLoading(true);
    if (Role === "admin") {
      CreateSubAdmin(val)
        .then((res) => {
          if(res.status){
            dispatchtoast(res.message)
          setOpen(false);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          apiCall();
          setBtnLoading(false);
        });
    } 
  };
  return (
    <>
      <Modal
      maskClosable={false}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        title={`Add Sub Admin`}
      >
        <Form
          onFinish={(val) => handleAdd(val)}
          onFinishFailed={(err) => console.log(err)}
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 place-items-center">
            <Form.Item
              className="w-full"
              rules={[
                {
                  required: true,
                  message:
                    "Enter login i'd",
                },
           
              ]}
              name={ "loginId"}
            >
              <Input
                placeholder={
                 "Enter login i'd"
                }
              />
            </Form.Item>
            <Form.Item
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Enter name",
                },
              ]}
              name={"name"}
            >
              <Input placeholder="Enter name" />
            </Form.Item>
            <Form.Item
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Enter mobile",
                },
                {
                  pattern: mobilePattern,
                  message: "Enter valid mobile",
                },
              ]}
              name={"mobile"}
            >
              <Input placeholder="Enter mobile" />
            </Form.Item>
            <Form.Item
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Enter password",
                },
              ]}
              name={"password"}
            >
              <Input placeholder="Enter password" />
            </Form.Item>
          </div>
          <div className="flex gap-2 justify-end items-center">
            <Button>Cancel</Button>
            <Button loading={btnLoading}
              style={{ color: "white" }}
              className="bg-btnColor text-white focus:border-none hover:border-none"
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddSubAdmin;

import React, { useEffect, useRef, useState } from "react";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Radio,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  DoWithdrawalReq,
  FetchWithdrawalReq,
  WithdrawalReqAction,
} from "../../../ApiServices/Apifun";
import { FaEdit, FaEye } from "react-icons/fa";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";
import { useNavigate } from "react-router-dom";

const WithdrawalRequest = () => {
  const [form]=Form.useForm()
  const formref=useRef()
  const profileDetail = getLocalStorageItem("userDetails");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [remark, setRemark] = useState("");
  const [status, setstatus] = useState("rejected");
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);

  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  useEffect(() => {
    if (profileDetail.role === "sub_admin") {
      if (!getLocalStorageItem("services").includes(2)) {
        navigate("/not-available");
        dispatch(setselectedTab("5"));
      }
    }
    dispatch(setselectedTab("5"));
    getReport();
    dispatch(setHeading("Withdrawal Request"));
   //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ReportColumn = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <p className="font-medium w-[150px]">
          {new Date(`${text}`).toDateString()}
        </p>
      ),
    },
    {
      title: "Transaction Id",
      dataIndex: "_id",
      key: "_id",
      render: (text) => {
        return (
          <>
            <p className=" font-medium  overflow-y-scroll">{text}</p>
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <p style={{ width: "80px" }} className=" font-medium">
          {text} Rs.
        </p>
      ),
    },
    {
      title: "Bank Detail",
      dataIndex: "bank_detail",
      key: "bank_detail",
      render: (text) => (
        <Tooltip placement="bottom" title={
          <div>
           <p> Acc No:- {text.ac_no}</p>
            <p>Bank:- {text.bank}</p>
           <p> IFSc: {text.ifsc_code}</p>
           <p> Ac Holder Name: {text.ac_holder_name}</p>
          </div>
        }>
          <div className="bg-primary cursor-pointer flex justify-center items-center h-10 rounded-full">
        <FaEye color="white" size={15} />
      </div>
        </Tooltip>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
      render: (text) => {
        return (
          <>
            <Tooltip title={text}>
              <p className="font-medium w-[150px]  overflow-y-scroll">
                {text ?? "-"}
              </p>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <>
            {text === "approved" ? (
              <Tag color="green">Approved</Tag>
            ) : text === "rejected" ? (
              <Tag color="red">Rejected</Tag>
            ) : (
              <Tag color="yellow">Under Process</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text, record) => {
        return (
          <>
            <Popconfirm
              placement="left"
              title="Approve Or Reject"
              className="popover"
              description={
                <div className="mt-4">
                  <div
                    className={`${status === "rejected" ? "block" : "hidden"}`}
                  >
                    <p className="font-semibold">Remark</p>
                    <Input
                      placeholder="Enter Remark"
                      onChange={(e) => setRemark(e.target.value)}
                      className="mt-2"
                    />
                  </div>
                  <Radio.Group
                    className="mt-2"
                    onChange={(e) => setstatus(e.target.value)}
                    value={status}
                  >
                    <Radio value={"rejected"}>Rejected</Radio>
                    <Radio value={"approved"}>Approved</Radio>
                  </Radio.Group>
                </div>
              }
              onConfirm={() => handleAction(record)}
              onCancel={() => {}}
              okText={"Ok "}
              okButtonProps={{ className: "bg-primary" }}
              cancelText="No"
            >
              <div className="bg-primary cursor-pointer flex justify-center items-center h-10 rounded-full">
                <FaEdit color="white" size={15} />
              </div>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  function handleAction(data) {
    const payload = {
      remark: remark ? remark : " ",
      status: status,
      withDrawReqId: data._id,
    };
    WithdrawalReqAction(payload)
      .then((res) => getReport())
      .catch((err) => console.log(err));
  }
  const getReport = (val) => {
    setPageLoading(true);
    FetchWithdrawalReq(
      `?status=${val?.status ?? "under_process"}&type=${val?.type ?? ""}`
    )
      .then((res) => {
        if (res.status) {
          setData(res.data.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoading(false));
  };

  const handleDoWithdrawalReq = () => {
    DoWithdrawalReq({ amount: withdrawalAmount })
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="">
        {profileDetail.role !== "admin" && (
          <div className="mb-2">
            <Popconfirm
              placement="bottomLeft"
              title="Do Withdrawal Request"
              className="popover"
              description={
                <div className="mt-4">
                  <Input
                    placeholder="Enter Amount"
                    onInput={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                    onChange={(e) => setWithdrawalAmount(e.target.value)}
                    className="mt-2"
                  />
                </div>
              }
              onConfirm={() => handleDoWithdrawalReq()}
              onCancel={() => {}}
              okText={"Ok "}
              okButtonProps={{ className: "bg-primary" }}
              cancelText="No"
            >
              <Button
                style={{ color: "white" }}
                className="border-none outline-none  transition-transform hover:border-none hover:outline-none bg-primary"
              >
                Do Withdrawal Request
              </Button>
            </Popconfirm>
          </div>
        )}
        <div className="prepaid  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
          <div className="flex justify-end items-center">
            <p onClick={()=>{
              getReport("")
              formref.current.resetFields()
            }} className="font-medium text-xs cursor-pointer bg-red-600 hover:bg-red-600 text-white px-2 py-1 rounded-md text-[15px] mb-2">
              Clear Filter
            </p>
          </div>
          <div className="mt-2">
            <Form form={form} ref={formref} onFinish={(val) => getReport(val)}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 place-items-center">
                <Form.Item name="status" className="w-full">
                  <Select
                    value={"under_process"}
                    className="w-full"
                    placeholder="Select Status"
                    options={[
                      { label: "under_process", value: "under_process" },
                      { label: "Rejected", value: "rejected" },
                      { label: "Aprroved", value: "approved" },
                    ]}
                  />
                </Form.Item>
                <Form.Item name={"type"} className="w-full">
                  <Select
                    placeholder="Select type"
                    allowClear={true}
                    options={[{ label: "Self", value: "self" }]}
                  />
                </Form.Item>
                <Form.Item className="w-full">
                  <Button
                    htmlType="submit"
                    style={{ color: "white" }}
                    className="w-full border-none hover:border-none outline-none hover:outline-none bg-primary text-white"
                  >
                    Filter
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-4">
          <Spin spinning={pageLoading}>
            <Table
              className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
              columns={ReportColumn}
              pagination={false}
              dataSource={data}
            />
            {/* <div className="mt-2 mb-8 flex justify-end items-center">
               <CommonPagination totalPages={totalPages} current={current} pageSize={pageSize} setCurrent={setCurrent} setPageSize={setPageSize}/>
              </div> */}
          </Spin>
        </div>
      </div>
    </>
  );
};

export default WithdrawalRequest;

import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";
import {
  updateDealer,
  updateDealterToUserWallet,
  updateDistributor,
  updateadmindisdeaWallet,
} from "../../../ApiServices/Apifun";
import { useSelector } from "react-redux";

const UpdateWallet = ({ open, setOpen, apiCall, record }) => {
  console.log(record);
  const Role = getLocalStorageItem("userDetails").role;
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const formref = useRef();
  const [status, setSttaus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const walletBal = useSelector((state) => state.admin.walletBal);

  useEffect(() => {
    formref.current.setFieldsValue({
      name: record.name,
    });
    setSttaus(record.status);
  }, []);
  const handleUpdate = (val) => {
    // if (
    //   record.wallet_balance < Number(val.Amount) &&
    //   val.transactionType === "dr"
    // ) {
    //   dispatchtoast(
    //     `${Role} don't have enough balance to deduct amount 😊!`,
    //     true
    //   );
    //   return setOpen(false);
    // }
    // if (walletBal < Number(val.Amount) && val.transactionType === "cr") {
    //   return dispatchtoast(
    //     `You  don't have enough balance to credit amount 😊!`,
    //     true
    //   );
    // }
    setBtnLoading(true);
    const payload = {
      transactionType: val.transactionType,
      Amount: Number(val.Amount),
    };
    if (Role === "admin" || Role == "distributor") {
      updateadmindisdeaWallet(payload, `${record.id}`)
        .then((res) => {
          if (res.status) {
            setOpen(false);
            dispatchtoast(res.message);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          apiCall();
          setBtnLoading(false);
        });
    } else if (Role === "dealer") {
      updateDealterToUserWallet(payload, record["id"])
        .then((res) => {
          if (res.status) {
            setOpen(false);
            dispatchtoast(res.message);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          apiCall();
          setBtnLoading(false);
        });
    }
  };
  return (
    <>
      <Modal footer={null} open={open} onCancel={() => setOpen(false)}>
        <Form
          form={form}
          className="mt-8"
          ref={formref}
          onFinishFailed={(err) => console.log(err)}
          onFinish={(val) => handleUpdate(val)}
        >
          <div className="grid grid-cols-2 gap-x-4 place-items-center">
            <Form.Item
              className="w-full"
              label="Txn. Type"
              name={`transactionType`}
              labelCol={{ span: 24 }}
            >
              <Select
                placeholder="Select Txn. Type"
                className="w-full"
                options={[
                  {
                    labeel: "CR",
                    value: "cr",
                  },
                  {
                    labeel: "DR",
                    value: "dr",
                  },
                ]}
              />
            </Form.Item>{" "}
            <Form.Item
              className="w-full"
              label="Amount"
              name={`Amount`}
              labelCol={{ span: 24 }}
            >
              <Input
                name="Amount"
                className="rounded-none w=full"
                placeholder="Enter amount"
              />
            </Form.Item>{" "}
          </div>
          <Form.Item>
            <div className="flex justify-end items-center gap-4">
              <Button>Cancel</Button>
              <Button
                loading={btnLoading}
                className="bg-btnColor hover:border-none focus:border-none"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Update
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateWallet;

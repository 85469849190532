import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { Button, Pagination, Switch, Table } from "antd";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";
import { Getnews, editnews } from "../../../ApiServices/Apifun";
// import { MdDelete } from "react-icons/md";
import AddNewsModal from "./AddNewsModal";

const News = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [current, setCurrent] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);

  const getListData = async () => {
    setLoading(true);
    Getnews(`?page=${1}&limit=10`)
      .then((res) => {
        if (res.status) {
          setCurrent(res.page);
          setTotalPages(res.total);
          setDataSource(
            res.data.map((i, index) => {
              return { ...i, sno: index + 1 };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("13"));
    dispatch(setHeading("News"));
    getListData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
//   const handleDelete = (data) => {
//     RemoveOffer(`offerId=${data._id}`)
//       .then((res) => {
//         if (res.status) {
//           dispatchtoast(res.message);
//           getListData();
//         }
//       })
//       .catch((err) => console.log(err));
//   };
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Heading",
      dataIndex: "heading",
      key: "heading",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      render: (text) => <p className="uppercase">{text}</p>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <div className="w-[200px] text-justify">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (text) => {
        return text ? (
          <p className="bg-green-700 p-1 text-center w-[100px] text-white">
            Active
          </p>
        ) : (
          <p className="p-1 text-center text-white w-[100px] bg-red-800">
            In Active
          </p>
        );
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "",
    //   render: (text, record) => {
    //     return (
    //       <Popconfirm
    //         onConfirm={() => handleDelete(record)}
    //         okButtonProps={{ className: "bg-primary hover:bgprimary" }}
    //         title="Are you sure you want to delete?"
    //         placement="left"
    //       >
    //         <div className="bg-primary p-2 flex justify-center items-center rounded-full text-center  ">
    //           <MdDelete size={20} color="white" />
    //         </div>
    //       </Popconfirm>
    //     );
    //   },
    // },
    {
        title: "News status",
        dataIndex: "",
        key: "",
        render: (text, record) => (
          <div
            className="flex  justify-center  items-center"
          >
            <Switch
            disabled={getLocalStorageItem("userDetails") &&
            getLocalStorageItem("userDetails").role !== "admin" &&  getLocalStorageItem("userDetails").role !== "sub_admin"}
            checked={record.is_active}
              value={record.is_active}
              checkedChildren={<p>Active</p>}
              unCheckedChildren={<p className="text-black">Inactive</p>}
              onChange={(val) =>
                editnews({ status: val?1:0, record_id: record._id })
                  .then((res) => {
                    if (res.status) {
                      getListData();
                      dispatchtoast(res.message);
                    }
                  })
                  .catch((err) => console.log(err))
              }
              className=""
            />
          </div>
        ),
      },
  ];
  return (
    <>
      {getLocalStorageItem("userDetails") &&
        getLocalStorageItem("userDetails").role === "admin" && (
          <div className="flex justify-end items-center">
            <Button
              onClick={() => setOpenAddModal(true)}
              style={{ color: "white" }}
              className="bg-primary border-none hover:border-none"
            >
              Add News
            </Button>
          </div>
        )}
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={columns}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={dataSource}
        />
        <div className="mt-2 mb-8 flex justify-end items-center">
          <Pagination
            showQuickJumper={false}
            defaultCurrent={current}
            current={current}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
      </div>
      {openAddModal && (
        <AddNewsModal
          apiCall={getListData}
          open={openAddModal}
          setOpen={setOpenAddModal}
        />
      )}
    </>
  );
};

export default News;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { Button, Pagination, Table } from "antd";
import AddSubAdmin from "./AddSubAdmin";
import { TbLockAccess } from "react-icons/tb";
import { BsFillPencilFill } from "react-icons/bs";
import { GetSubAdmin } from "../../../ApiServices/Apifun";
import UpdateSubAdminModal from "./UpdateSubAdmin";
import PermissionModal from "./PermissionModal";
import { FaEye } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { getLocalStorageItem } from "../../../Utils";

const SubAdmin = () => {
  const dispatch = useDispatch();
  const profileDetail = getLocalStorageItem("userDetails");
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [current, setCurrent] = useState(1);
  const [openAddSubAdminModal, setOpenSubAdminModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [onlyforShow, setOnlyForShow] = useState(false);
  const [record, setRecord] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const getListData = async () => {
    setLoading(true);
    GetSubAdmin(`?page=${current}&limit=10`)
      .then((res) => {
        if (res.status) {
          setCurrent(res.page);
          setTotalPages(res.total);
          setDataSource(
            res.data.map((i, index) => {
              return {
                id: i["_id"],
                sno: index + 1,
                name: i.name,
                email: i.email,
                mobile: i.mobile,
                role: i.role,
                permissions: i.permissions,
                status: i.status,
                wallet_balance: i.wallet_balance,
                loginId: i.loginId,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (profileDetail.role === "sub_admin" || profileDetail.role !== "admin") {
      return navigate(-1);
    }
    dispatch(setselectedTab("10"));

    dispatch(setHeading("Sub Admins"));
    getListData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "Balance",
      dataIndex: "wallet_balance",
      key: "wallet_balance",
      render: (text) => <p>{text} Rs.</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return text ? (
          <p className="bg-green-700 p-1 text-center w-[100px] text-white">
            Active
          </p>
        ) : (
          <p className="p-1 text-center text-white w-[100px] bg-red-800">
            In Active
          </p>
        );
      },
    },
    {
      title: "Deleted Status",
      dataIndex: "delete_status",
      key: "delete_status",
      render: (text) => {
        return text ? (
          <p className="bg-green-700 p-1 text-center w-[100px] text-white">
            Active
          </p>
        ) : (
          <p className="p-1 text-center text-white w-[100px] bg-red-800">
            In Active
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <p
          onClick={() => {
            setOpenUpdateModal(true);
            setRowData(record);
          }}
        >
          <BsFillPencilFill
            className="bg-primary text-white flex justify-center items-center p-2"
            size={25}
          />
        </p>
      ),
    },
    {
      title: "Permission",
      dataIndex: "txn.",
      key: "txn.",
      render: (text, record) => {
        return (
          <div className="flex justify-center items-center gap-x-3">
            <p
              onClick={() => {
                setRecord(record);
                setOpenPermissionModal(true);
              }}
            >
              <TbLockAccess
                className="bg-primary text-white flex justify-center items-center p-2"
                size={25}
              />
            </p>{" "}
            <p
              onClick={() => {
                setOpenPermissionModal(true);
                setOnlyForShow(true);
              }}
            >
              <FaEye
                className="bg-primary text-white flex justify-center items-center p-2"
                size={25}
              />
            </p>
          </div>
        );
      },
    },
    {
      title: "Remove Permission",
      dataIndex: "txn.",
      key: "txn.",
      render: (text, record) => {
        return (
          <div className="flex justify-center items-center gap-x-3">
            <p
              onClick={() => {
                setIsRemoving(true);
                setRecord(record);
                setOpenPermissionModal(true);
              }}
            >
              <TbLockAccess
                className="bg-primary text-white flex justify-center items-center p-2"
                size={25}
              />
            </p>{" "}
          </div>
        );
      },
    },
    // {
    //   title: "Cr/Dr wallet",
    //   dataIndex: ".",
    //   key: ".",
    //   render: (text,record) => (
    //     <p  onClick={()=>{
    //       setOpenUpdateWallet(true)
    //       setRowData(record)
    //     }}>
    //       <FaMoneyBill color="green"  className="bg-primary text-white flex justify-center items-center p-2" size={25}/>
    //     </p>
    //   ),
    // },
    // {
    //   title: profileDetail.role==="admin"?"Show Dealer":profileDetail.role==="distributor"?"Show User":null,
    //   dataIndex: ".",
    //   key: ".",
    //   render: (text,record) => (
    //     <p className="" onClick={()=>{
    //       navigate("/under-admin",{state:record})
    //     }}>
    //       <FaUser  className="bg-primary text-white flex justify-center items-center p-2" size={25}/>
    //     </p>
    //   ),
    // },
  ];
  return (
    <>
      <div className="flex justify-end items-center">
        <Button
          onClick={() => setOpenSubAdminModal(true)}
          style={{ color: "white" }}
          className="bg-primary text-white border-none hover:border-none"
        >
          Create SubAdmin
        </Button>
      </div>
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={columns}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={dataSource}
        />
        <div className="mt-2 mb-8 flex justify-end items-center">
          <Pagination
            showQuickJumper={false}
            defaultCurrent={current}
            current={current}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
      </div>
      {openAddSubAdminModal && (
        <AddSubAdmin
          open={openAddSubAdminModal}
          setOpen={setOpenSubAdminModal}
        />
      )}
      {openPermissionModal && (
        <PermissionModal
          setOnlyForShow={setOnlyForShow}
          open={openPermissionModal}
          record={record}
          isRemoving={isRemoving}
          setIsRemoving={setIsRemoving}
          apiCall={getListData}
          onlyForShow={onlyforShow}
          setOpen={setOpenPermissionModal}
        />
      )}
      {openUpdateModal && (
        <UpdateSubAdminModal
          apiCall={getListData}
          open={openUpdateModal}
          setOpen={setOpenUpdateModal}
          record={rowData}
        />
      )}
    </>
  );
};

export default SubAdmin;

import { Drawer, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { GetNotifications } from "../ApiServices/Apifun";
import { days } from "../Utils";
import {  useNavigate } from "react-router-dom";

const NotificationDrawer = ({ open, setopen }) => {
    const navigate=useNavigate()
  const [spin, setspin] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const getNotifications = () => {
    setspin(true);
    GetNotifications()
      .then((res) => {
        if (res.status) {
          setNotifications(res.data.Notifications);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setspin(false));
  };
  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <Drawer
      title="Notification"
      width={500}
      //   headerStyle={{
      //     background: ThemeColor.primary,
      //     color: ThemeColor.bswhite,
      //   }}
      placement="right"
      onClose={() => setopen(false)}
      open={open}
    >
      <Spin spinning={spin}>
        {notifications.length!==0? notifications.map((i, index) => {
          return (
            <div key={index}>
              
                <div
                onClick={()=>{
                    navigate( i?.request_type === "Fund_Request"
                    ? "/req-report"
                    : "/withdrawal-req")
                    setopen(false)
                }}
                className="flex my-3 cursor-pointer justify-between items-start ">
                  <div>
                    <p className="font-semibold">
                      {i?.request_type?.split("_").join(" ")}
                    </p>
                    <p className="text-bsgray600">
                      You have received a fund request by this {i.request_id} id
                    </p>
                  </div>
                  <p className="text-bsgray600 font-semibold">
                    {days[new Date(i?.createdAt)?.getDay()]}
                  </p>
                </div>
              <hr />
            </div>
          );
        }):<div className="flex justify-center items-center h-screen">
            <p className="uppercase font-bold">No new notification!</p>
            </div>}
      </Spin>
    </Drawer>
  );
};

export default NotificationDrawer;

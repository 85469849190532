import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";
import { updateDealer, updateDistributor, updateUser } from "../../../ApiServices/Apifun";

const UpdateModal = ({ open, setOpen, apiCall, record }) => {
  console.log(record);
  const Role = getLocalStorageItem("userDetails").role;
const[btnLoading,setBtnLoading]=useState(false)
  const [form] = Form.useForm();
  const formref = useRef();
  const [status, setSttaus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  useEffect(() => {
    formref.current.setFieldsValue({
      name: record.name,
    });
    setSttaus(record.status);
  }, []);
  const handleUpdate=(val)=>{
    setBtnLoading(true)
    const payload={
        name:val.name,
        status:status,
        delete_status:deleteStatus
    }
        if (Role === "admin") {
          updateDistributor(payload,`/${record.id}`)
            .then((res) => {
              if(res.status){
                setOpen(false);
                dispatchtoast(res.message)
              }
            })
            .catch((err) => console.log(err))
            .finally(() => {
              apiCall();
              setBtnLoading(false)
            });
        } else if (Role === "distributor") {
          updateDealer(payload,`/${record.id}`)
          .then((res) => {
            if(res.status){
              setOpen(false);
              dispatchtoast(res.message)
            }
          })
          .catch((err) => console.log(err))
          .finally(() => {
            apiCall();
            setBtnLoading(false)
          });
        } 
        else if (Role === "dealer") {
          updateUser(payload,record["id"])
          .then((res) => {
            if(res.status){
              setOpen(false);
              dispatchtoast(res.message)
            }
          })
          .catch((err) => console.log(err))
          .finally(() => {
            apiCall();
            setBtnLoading(false)
          });
        } 
  }
  return (
    <>
      <Modal footer={null} open={open} onCancel={() => setOpen(false)}>
        <Form
          form={form}
          className="mt-8"
          ref={formref}
          onFinishFailed={(err) => console.log(err)}
          onFinish={(val) => handleUpdate(val)}
        >
          {Role!=="dealer"?<Form.Item rules={[
            {
                required:true,
                message:"Name is required"
            }
          ]} label="Name" labelCol={{ span: 24 }} name="name">
            <Input name="name" placeholder="Enter name" />
          </Form.Item>:""}
          
          <div className="grid grid-cols-2 place-items-center">
            <Form.Item
              name={`status`}
              labelCol={{ span: 24 }}
            >
              <div className="mt-2 flex justify-start items-center gap-x-3  checkbox">
                <input
                  checked={status}
                  onChange={(e) => setSttaus(e.target.checked)}
                  className="rounded-md"
                  type="checkbox"
                />
                <p className="text-textGray required">Active</p>
              </div>
            </Form.Item>{" "}
            <Form.Item
              name={`delete_status`}
              labelCol={{ span: 24 }}
            >
              <div className="mt-2 flex justify-start items-center gap-x-3  checkbox">
                <input
                  onChange={(e) => setDeleteStatus(e.target.checked)}
                  className="rounded-md"
                  type="checkbox"
                />
                <p className="text-textGray required">Delete</p>
              </div>
            </Form.Item>{" "}
          </div>
          <Form.Item>
            <div className="flex justify-end items-center gap-4">
              <Button >Cancel</Button>
              <Button loading={btnLoading} className="bg-btnColor hover:border-none focus:border-none" style={{color:"white"}} htmlType="submit">Update</Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateModal;
